import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./App.css";
import "react-international-phone/style.css";
import Dashboard from "./pages/home/Dashboard";
import SignIn from "./pages/authentication/SignIn";
import SignUp from "./pages/authentication/SignUp";
import { ThemeProvider, createTheme } from "@mui/material";
import NotFound404 from "./pages/404";
import Users from "./pages/home/Users";
import Companies from "./pages/home/Companies";
import DocumentCategory from "./pages/document-management/DocumentCategory";
import DocumentType from "./pages/document-management/DocumentType";
import FileCategory from "./pages/document-management/FileCategory";
import FileMaster from "./pages/document-management/FileMaster";
import Uploads from "./pages/document-management/uploads/Uploads";
import TaskCategory from "./pages/tast-management/TaskCategory";
import Tasks from "./pages/tast-management/Tasks";
import FileLocation from "./pages/document-management/FileLocation/FileLocation";
import { ProSidebarProvider } from "react-pro-sidebar";
import { getAuthUser } from "./components/common";
import UploadsGridView from "./pages/document-management/uploads/UploadsGridView";
import Profile from "./pages/Profile";
import UploadsTableView from "./pages/document-management/uploads/UploadsTableView";
import UploadsFolderView from "./pages/document-management/uploads/document-locations/UploadsFolderView";
import Buildings from "./pages/document-management/FileLocation/level1/Buildings";
import Buildings2 from "./pages/document-management/FileLocation/level1/Buildings2";
import Room from "./pages/document-management/FileLocation/level2/Room";
import Almirah from "./pages/document-management/FileLocation/level3/Almirah";
import Drawer from "./pages/document-management/FileLocation/level4/Drawer";
import DocumentUploadCategory from "./pages/document-management/uploads/document-locations/01DocumentUploadCategory";
import DocumentUploadType from "./pages/document-management/uploads/document-locations/02DocumentUploadType";
import AddDocumentList from "./pages/home/AddDocumentList";
import React from "react";
import CredentialCategory from "./pages/credential/CredentialCategory";
import Credential from "./pages/credential/Credential";
import SharingDashboard from "./pages/home/SharingDashboard";
import GridPreview from "./pages/document-management/uploads/GridPreview";
import ConfirmOTP from "./pages/home/ConfirmOTP";
import DocPreviewShare from "./pages/home/DocPreviewShare";
import DashboardCredential from "./pages/home/DashboardTab/DashboardCredential";
import DashboardUploads from "./pages/home/DashboardTab/DashboardUploads";
import DashboardTask from "./pages/home/DashboardTab/DashboardTask";

const theme = createTheme({
  typography: {
    fontSize: 12,
    fontFamily: ["Nunito"],
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          borderRadius: 8,
        },
      },
    },
  },
});
export default function App() {
  const [docViewFilter, setdocViewFilter] = React.useState({
    document_category_id: null,
    document_category: "",
    document_type_id: null,
    document_type: "",
    field_name: "",
    fileType: [],
    field_value: "",
  });
  const routes = createBrowserRouter([
    {
      path: "/",
      element: !getAuthUser() ? (
        <Navigate to={"/sign-in"} />
      ) : (
        <Navigate to={"/dashboard"} />
      ),
    },
    {
      path: "/sign-in",
      element: !getAuthUser() ? <SignIn /> : <Navigate to={"/dashboard"} />,
    },
    {
      path: "/dashboard",
      element: !getAuthUser() ? (
        <Navigate to={"/sign-in"} />
      ) : (
        <Navigate to={"/dashboard-tasks"} />
      ),
    },
    {
      path: "/dashboard-credential",
      element: <DashboardCredential />,
    },
    {
      path: "/dashboard-uploads",
      element: <DashboardUploads />,
      // children:[
      //  { path:"/",
      // element: <AddDocumentList filterData={docViewFilter} />
      // }
      // ]
    },
    {
      path: "/add-document",
      element: !getAuthUser() ? (
        <Navigate to={"/sign-in"} />
      ) : (
        <AddDocumentList filterData={docViewFilter} />
      ),
    },
    {
      path: "sign-up",
      element: <SignUp />,
    },
    {
      path: "/users",
      element: !getAuthUser() ? <SignIn /> : <Users />,
    },
    {
      path: "/companies",
      element: !getAuthUser() ? <SignIn /> : <Companies />,
    },
    {
      path: "/document-category",
      element: !getAuthUser() ? <SignIn /> : <DocumentCategory />,
    },
    {
      path: "/document-type",
      element: !getAuthUser() ? <SignIn /> : <DocumentType />,
    },
    // {
    //   path: "/document-master",
    //   element: <DocumentMaster />,
    // },
    {
      path: "/file-category",
      element: !getAuthUser() ? <SignIn /> : <FileCategory />,
    },
    {
      path: "/file-master",
      element: !getAuthUser() ? <SignIn /> : <FileMaster />,
    },
    {
      path: "/file-location",
      element: !getAuthUser() ? <SignIn /> : <FileLocation />,
      children: [
        {
          path: "building1",
          element: !getAuthUser() ? <SignIn /> : <Buildings />,
          children: [
            {
              path: "room1",
              element: <Room />,
              children: [
                {
                  path: "almirah1",
                  element: <Almirah />,
                  children: [
                    {
                      path: "drawer1",
                      element: <Drawer />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "building2",
          element: !getAuthUser() ? <SignIn /> : <Buildings2 />,
        },
      ],
    },
    {
      path: "preview",
      element: !getAuthUser() ? <SignIn /> : <GridPreview />,
    },
    {
      path: "uploads",
      element: !getAuthUser() ? <SignIn /> : <Uploads />,
      // children: [
      //   {
      //     path: "grid",
      //     element: <UploadsGridView filterData={docViewFilter} />,
      //   },
      //   {
      //     path: "folder",
      //     element: <UploadsFolderView />,
      //   },
      // ],
    },
    // {
    //   path: "dashboard-uploads",
    //   element: !getAuthUser() ? (
    //     <SignIn />
    //   ) : (
    //     <DashboardUploads filterData={setdocViewFilter} />
    //   ),
    //   children: [
    //     {
    //       path: "grid",
    //       element: <UploadsGridView filterData={docViewFilter} />,
    //     },
    //     {
    //       path: "folder",
    //       element: <UploadsFolderView />,
    //     },
    //   ],
    // },
    {
      path: "/sign-in",
      element: !getAuthUser() ? <SignIn /> : <Navigate to={"/dashboard"} />,
    },
    {
      path: "/dashboard",
      element: !getAuthUser() ? <Navigate to={"/sign-in"} /> : <Dashboard />,

      // children: [
      //   {
      //     path: "tasks",
      //     element: <DashboardTask />,
      //   },
      //   {
      //     path: "uploads",
      //     element: <DashboardUploads />,
      //   },
      //   {
      //     path: "credentials",
      //     element: <DashboardCredential />,
      //   },
      // ],
    },
    {
      path: "/add-document",
      element: !getAuthUser() ? (
        <Navigate to={"/sign-in"} />
      ) : (
        <AddDocumentList filterData={docViewFilter} />
      ),
    },
    {
      path: "/dashboard-tasks",
      element: <DashboardTask />,
    },
    {
      path: "/dashboard-uploads",
      element: <DashboardUploads filterData={setdocViewFilter} />,
    },
    {
      path: "/dashboard-credentials",
      element: <DashboardCredential />,
    },
    {
      path: "sign-up",
      element: <SignUp />,
    },
    {
      path: "/users",
      element: !getAuthUser() ? <SignIn /> : <Users />,
    },
    {
      path: "/companies",
      element: !getAuthUser() ? <SignIn /> : <Companies />,
    },
    {
      path: "/document-category",
      element: !getAuthUser() ? <SignIn /> : <DocumentCategory />,
    },
    {
      path: "/document-type",
      element: !getAuthUser() ? <SignIn /> : <DocumentType />,
    },
    {
      path: "/dashboard/sharing",
      element: <SharingDashboard />,
    },
    {
      path: "/file-category",
      element: !getAuthUser() ? <SignIn /> : <FileCategory />,
    },
    {
      path: "/file-master",
      element: !getAuthUser() ? <SignIn /> : <FileMaster />,
    },
    {
      path: "/file-location",
      element: !getAuthUser() ? <SignIn /> : <FileLocation />,
      children: [
        {
          path: "building1",
          element: !getAuthUser() ? <SignIn /> : <Buildings />,
          children: [
            {
              path: "room1",
              element: <Room />,
              children: [
                {
                  path: "almirah1",
                  element: <Almirah />,
                  children: [
                    {
                      path: "drawer1",
                      element: <Drawer />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "building2",
          element: !getAuthUser() ? <SignIn /> : <Buildings2 />,
        },
      ],
    },
    // {
    //   path: "uploads",
    //   element: !getAuthUser() ? (
    //     <SignIn />
    //   ) : (
    //     <Uploads filterData={setdocViewFilter} />
    //   ),
    //   children: [
    //     {
    //       path: "table",
    //       element: <UploadsTableView />,
    //     },
    //     {
    //       path: "grid",
    //       element: <UploadsGridView filterData={docViewFilter} />,
    //     },
    //     {
    //       path: "folder",
    //       element: <UploadsFolderView />,
    //     },
    //   ],
    // },
    {
      path: "uploads",
      element: <Uploads />,
    },
    {
      path: "preview",
      element: !getAuthUser() ? <SignIn /> : <GridPreview />,
    },

    // {
    //   path: "/uploads",
    //   element: !getAuthUser() ? <SignIn /> : <Uploads />,
    //   children: [
    //     {
    //       path: "document-category",
    //       element: !getAuthUser() ? <SignIn /> : <DocumentUploadCategory />,
    //     },
    //     {
    //       path: "document-type",
    //       element: !getAuthUser() ? <SignIn /> : <DocumentUploadType />,
    //     },
    //   ],
    // },
    {
      path: "/task-category",
      element: !getAuthUser() ? <SignIn /> : <TaskCategory />,
    },
    {
      path: "/tasks",
      element: !getAuthUser() ? <SignIn /> : <Tasks />,
    },
    {
      path: "/profile",
      element: !getAuthUser() ? <SignIn /> : <Profile />,
    },
    {
      path: "*",
      element: !getAuthUser() ? <SignIn /> : <NotFound404 />,
    },
    {
      path: "/credential-category",
      element: !getAuthUser() ? <SignIn /> : <CredentialCategory />,
    },
    {
      path: "/credential",
      element: !getAuthUser() ? <SignIn /> : <Credential />,
    },
    {
      path: "/docpreviewShare",
      element: !getAuthUser() ? <SignIn /> : <DocPreviewShare />,
    },
    {
      path: "/confirm-otp/contact_person/:contact_person/mobile/:mobile",
      element: !getAuthUser() ? <SignIn /> : <ConfirmOTP />,
    },
  ]);
  return (
    <>
      <ProSidebarProvider>
        <ThemeProvider theme={theme}>
          <RouterProvider router={routes} />
        </ThemeProvider>
      </ProSidebarProvider>
    </>
  );
}
