import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";


function CircularProgressWithLabel(props) {  

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        disableShrink
        size={20}
        variant="determinate"
        {...props}        
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h6"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.time)}`}</Typography>
      </Box>
    </Box>
  );
}

export default function HeaderTime() {
  const [time, setTime] = useState({
    hrs: moment().format("h"),
    minutes: moment().format("mm"),
    seconds: moment().format("ss"),
    hrsValue: Math.ceil((parseInt(moment().format("h")) * 8 + 1) / 10) * 10,
    minValue: Math.ceil((parseInt(moment().format("mm")) * 2 + 1) / 10) * 10,
    secValue: Math.ceil((parseInt(moment().format("ss")) * 1.6 + 1) / 10) * 10,
  });

  const calculateTime = () => {
    setInterval(() => {
      setTime({
        ...time,
        hrsValue: Math.ceil((parseInt(moment().format("h")) * 8 + 1) / 10) * 10,
        hrs: moment().format("h"),
        secValue:
          Math.ceil((parseInt(moment().format("ss")) * 1.6 + 1) / 10) * 10,
        seconds: moment().format("ss"),
        minValue:
          Math.ceil((parseInt(moment().format("mm")) * 2 + 1) / 10) * 10,
        minutes: moment().format("mm"),
      });
    }, 1000);
  };

  useEffect(() => {
    calculateTime();
  });

  return (
    <div className="col d-flex justify-content-end gap-3">
      <div className="clock_container">
        <p className="mb-0">Week</p>
        <h6 className="mb-0 mx-auto week">{moment().isoWeek()}</h6>
      </div>
      <div className="clock_container">
        <p className="mb-0 text-center">
          {moment().format("dddd DD MMM YYYY")}
        </p>
        <div className="F d-flex justify-content-around gap-3">
          <div className="circle_box">
            <CircularProgressWithLabel
              className="hrs"
              value={time.hrsValue}
              time={time.hrs}
            />
          </div>
          <div className="circle_box">
            <CircularProgressWithLabel
              color="secondary"
              value={time.minValue}
              time={time.minutes}
            />
          </div>
          <div className="circle_box">
            <CircularProgressWithLabel
              className="seconds"
              value={time.secValue}
              time={time.seconds}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
