import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import classes from "./MainLayout.module.css";
import {
  FaArrowUp,
  FaCalendarCheck,
  FaChartBar,
  FaCheckDouble,
  FaCogs,
  FaFile,
  FaFileUpload,
  FaFolderOpen,
  FaHome,
  FaRegBuilding,
  FaRegCalendarCheck,
  FaRegCheckSquare,
  FaRegFile,
  FaRegFileAlt,
  FaRegFileArchive,
  FaRegListAlt,
  FaRegUser,
  FaSearchLocation,
  FaTasks,
  FaUserCheck,
} from "react-icons/fa";
import { useEffect, useState } from "react";

export default function SidebarMenu() {
  const location = useLocation();
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const routeMenuKeys = {
    "/dashboard": "home",
    "/users": "home",
    "/companies": "home",
    "/document-category": "document",
    "/document-type": "document",
    // "/document-master": "document",
    "/file-category": "document",
    "/file-master": "document",
    "/file-location": "document",
    "/uploads": "document",
    "/task-category": "task",
    "/tasks": "task",
    "/credential-category": "credential",
    "/credential": "credential",
  };

  useEffect(() => {
    const currentRoute = location.pathname;
    const submenuKey = routeMenuKeys[currentRoute] || null;
    setOpenSubMenu(submenuKey);
  }, [location.pathname]);

  const handleSubMenuClick = (key) => {
    setOpenSubMenu(key === openSubMenu ? null : key);
  };

  return (
    <>
      <Sidebar
        rootStyles={{
          minWidth: "100%",
          width: "100%",
        }}
        className={classes.sidebar_dropmenu}
      >
        <Menu
          menuItemStyles={{
            button: {
              [`&:hover`]: {
                backgroundColor: "#d6d6d6",
              },
              height: "28px",
            },
          }}
        >
          {/* <SubMenu
            label={`Dashboard`}
            onClick={() => handleSubMenuClick("dashboard")}
            icon={<FaChartBar />}
            component={<Link to="/dashboard" />}
            open={openSubMenu === "dashboard"}
          >
            <MenuItem
              component={<Link to="/dashboard" />}
              icon={<FaChartBar />}
            >
              Dashboard
            </MenuItem>
            <MenuItem component={<Link to="/users" />} icon={<FaRegUser />}>
              Users
            </MenuItem>
            <MenuItem
              component={<Link to="/companies" />}
              icon={<FaRegBuilding />}
            >
              Companies
            </MenuItem>
          </SubMenu> */}

          {/* Dashboard  */}
          <SubMenu
            label={`Dashboard`}
            onClick={() => handleSubMenuClick("dashboard")}
            icon={<FaChartBar />}
            open={openSubMenu === "dashboard"}
          >
            <MenuItem
              component={<Link to="/dashboard-tasks" />}
              icon={<FaCheckDouble />}
            >
              Task
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard-uploads" />}
              icon={<FaFileUpload />}
            >
              My Docs
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard-credentials" />}
              icon={<FaRegCalendarCheck  />}
            >
              Credential
            </MenuItem>
          </SubMenu>

          {/* Documents  */}
          <SubMenu
            label={`Documents`}
            onClick={() => handleSubMenuClick("document")}
            icon={<FaFolderOpen />}
            open={openSubMenu === "document"}
          >
            <MenuItem
              component={<Link to="/document-category" />}
              icon={<FaRegListAlt />}
            >
              Category
            </MenuItem>
            <MenuItem
              component={<Link to="/document-type" />}
              icon={<FaRegFileAlt />}
            >
              Type{" "}
            </MenuItem>

            <MenuItem
              component={<Link to="/uploads" />}
              icon={<FaArrowUp />}
            >
              Upload
            </MenuItem>
          </SubMenu>

          {/* File  */}
          <SubMenu
            label={`File`}
            onClick={() => handleSubMenuClick("file")}
            icon={<FaFolderOpen />}
            open={openSubMenu === "file"}
          >
            <MenuItem
              component={<Link to="/file-category" />}
              icon={<FaRegFileArchive />}
            >
              File Category
            </MenuItem>
            <MenuItem
              component={<Link to="/file-master" />}
              icon={<FaRegFile />}
            >
              File Master
            </MenuItem>
            <MenuItem
              component={<Link to="/file-location" />}
              icon={<FaSearchLocation />}
            >
              File Location
            </MenuItem>
          </SubMenu>
          {/* <MenuItem
              component={<Link to="/document-master" />}
              icon={<FaRegClipboard />}
            >
              Document Master
            </MenuItem> */}

          <SubMenu
            label={`Tasks`}
            icon={<FaTasks />}
            open={openSubMenu === "task"}
            onClick={() => handleSubMenuClick("task")}
          >
            <MenuItem
              component={<Link to="/task-category" />}
              icon={<FaRegCheckSquare />}
            >
              Category
            </MenuItem>
            <MenuItem
              component={<Link to="/tasks" />}
              icon={<FaRegCalendarCheck />}
            >
              Tasks
            </MenuItem>
          </SubMenu>
          <SubMenu
            label={`Credentials`}
            icon={<FaCalendarCheck />}
            open={openSubMenu === "credential"}
            onClick={() => handleSubMenuClick("credential")}
          >
            <MenuItem
              component={<Link to="/credential-category" />}
              icon={<FaRegCheckSquare />}
            >
              Category
            </MenuItem>
            <MenuItem
              component={<Link to="/credential" />}
              icon={<FaRegCalendarCheck />}
            >
              Credential
            </MenuItem>
          </SubMenu>
          <SubMenu
            label={`Settings`}
            icon={<FaCogs />}
            open={openSubMenu === "settings"}
            onClick={() => handleSubMenuClick("settings")}
          >
            <MenuItem component={<Link to="/users" />} icon={<FaRegUser />}>
              Users
            </MenuItem>
            <MenuItem
              component={<Link to="/companies" />}
              icon={<FaRegBuilding />}
            >
              Companies
            </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    </>
  );
}
