import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { ImDrawer } from "react-icons/im";
import { NavLink, Outlet } from "react-router-dom";

export default function Almirah() {
  return (
    <>
      <div className="file_location_main_wrapper locationlevel4">
        <div className="tabs">
          <ul>
            <li>
              <NavLink
                to="/file-location/building1/room1/almirah1/drawer1"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 01</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building1/room2"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 02</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 03</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 04</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 05</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 06</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 07</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 08</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 09</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 10</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 11</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <ImDrawer />
                <span>Drawer 12</span>
              </NavLink>
            </li>
          </ul>
          <div className="addBtn">
            <Button variant="none">
              <FaPlus /> Add Drawer
            </Button>
          </div>
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </>
  );
}
