import CommonDataGrid from "../../components/CommonDataGrid";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Checkbox, FormControlLabel, Switch, TextField } from "@mui/material";
import { CommonController } from "../../_redux/common.controller";
import { FaPenToSquare } from "react-icons/fa6";
import { FaEye, FaTrash } from "react-icons/fa";
import MainLayout from "../../components/MainLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

export default function CredentialCategory() {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    credential_category_id: 0,
    credential_category: "",
    company_id: [],
  });
  const handleClose = () => {
    setShow(false);
    setview(false);
    setFormData({
      credential_category_id: 0,
      credential_category: "",
      company_id: [],
    });
  };
  const handleShow = () => setShow(true);
  const [valid, setvalid] = useState(false);
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add Credential Category",
  });
  const [validSubmit, setvalidSubmit] = useState(false);
  const [view, setview] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);
  const [companyList, setcompanyList] = useState([]);
  const [filter, setfilter] = useState(false);

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sr No.",
        minSize: 29,
        size: 51,
        enableColumnFilter: false,
      },
      {
        accessorKey: "credential_category",
        header: "Credential Category",
        width: 400,
        enableColumnFilter: false,
        minSize: 98,
        size: 183,
      },
      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        minSize: 31,
        size: 73,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FaPenToSquare
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.credential_category_id,
                  type: false,
                  label: "Edit Credential Category",
                });
              }}
              style={{ cursor: "pointer", color: "blue" }}
            />
            <FaEye
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.credential_category_id,
                  type: true,
                  label: "Preview Credential Category",
                });
              }}
              style={{
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />{" "}
            <FaTrash
              onClick={() =>
                handleDeleteRow(row.original.credential_category_id)
              }
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangetoggle = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const CompanyList = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "auth/list_company_user",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setcompanyList(result.data);
            setFormData({ ...formData, company_id: result.data });
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };

  const submitDoc = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminCredential/insert_credential_category",
        formData,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setview(true);
            setupdateGrid(updateGrid + 1);
            setFormData({
              credential_category_id: 0,
              credential_category: "",
              company_id: [],
            });
            handleClose();
            toast.success("New Credential Category Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminCredential/delete_credential_category",
        {
          credential_category_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Succesfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setupdateGrid(2);
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleEdit = (data) => {
    handleShow();
    const val = { ...data };
    val.valid = true;
    seteditData(val);

    try {
      CommonController.commonApiCallFilter(
        "quickAdminCredential/preview_credential_category",
        {
          credential_category_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formData };
          // setvalidSubmit(true);

          for (let key in formData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setFormData(tempData);
        }
      });
    } catch (err) {
      console(err);
    }
  };

  const handleCountrySelect = (val) => {
    const arr = [...formData.company_id];
    return arr.filter((value) => value.company_id === val.company_id).length > 0
      ? true
      : false;
  };

  const handleBodyFilter = (data) => {
    setFormData(data);
    setfilter(false);
  };

  useEffect(() => {
    CompanyList();
  }, []);

  return (
    <MainLayout
      pageName="CredentialCategory"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Add Credential Category" });
        CompanyList();
      }}
      isAddBtnVisible={true}
    >
      <CommonDataGrid
        url={"quickAdminCredential/browse_credential_category"}
        columns={gridColumn}
        body={""}
        // bodyData={() => setvalid(false)}
        jsonUpd={updateGrid}
        gridHeight={370}
        bodyData={handleBodyFilter}
      />
      <ToastContainer />
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>{editData.label}</h4>
          <Form className="mt-3">
            <Row>
              <Col md={6}>
                <TextField
                  size="small"
                  label="Credential Category*"
                  variant="outlined"
                  className="w-100"
                  name="credential_category"
                  value={formData.credential_category}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6}>
                {editData.valid ? (
                  <Col md={2} className="d-flex">
                    <div className="text-center">
                      <FormControlLabel
                        control={<Switch />}
                        label="Disable User"
                        onChange={handleChangetoggle}
                        checked={formData.disable}
                        name="disable"
                        disabled={view}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Col>

              <Col md={6} className=" mt-3" style={{ marginLeft: "13px" }}>
                <Row>
                  <Table bordered size="sm" color="dark">
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyList.length > 0
                        ? companyList.map((val, ind) => {
                            return (
                              <tr key={val.company_id}>
                                <td className="text-left">
                                  {val.company_name}
                                </td>
                                <td className="text-center">
                                  <FormControlLabel
                                    control={<Checkbox />}
                                    onChange={(e) => {
                                      const valid = e.target.checked;
                                      if (valid) {
                                        const arr = [...formData.company_id];
                                        arr.push(val);
                                        setFormData({
                                          ...formData,
                                          company_id: arr,
                                        });
                                      } else {
                                        const arr = [...formData.company_id];
                                        const index = arr.findIndex(
                                          (value) =>
                                            value.company_id === val.company_id
                                        );
                                        arr.splice(index, 1);
                                        setFormData({
                                          ...formData,
                                          company_id: arr,
                                        });
                                      }
                                    }}
                                    checked={handleCountrySelect(val)}
                                    name={val.company_id}
                                    disabled={!editData.type ? false : true}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 mt-1 text-right">
            {!editData.type ? (
              <Button
                variant="success"
                size="sm"
                onClick={submitDoc}
                className="me-3"
              >
                {editData.valid ? "Update" : "Save"}
              </Button>
            ) : (
              ""
            )}
            <Button variant="danger" size="sm" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
}
