import React, { memo, useEffect, useState } from "react";
import {
  MultipleFileUpload,
  MultipleFileUploadMain,
  HelperText,
  HelperTextItem,
} from "@patternfly/react-core";
import UploadIcon from "@patternfly/react-icons/dist/esm/icons/upload-icon";
import { userService } from "../../../services";
import { Col, Row } from "react-bootstrap";
import { FaFile } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { nodeUrl } from "../../../config";
const UploadImage = ({ handleData, data, deleteData }) => {
  const [currentFiles, setCurrentFiles] = useState([]);
  const [readFileData, setReadFileData] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const [statusIcon, setStatusIcon] = useState("inProgress");
  if (!showStatus && currentFiles.length > 0) setShowStatus(true);

  useEffect(() => {
    if (readFileData.length < currentFiles.length) {
      setStatusIcon("inProgress");
    } else if (readFileData.every((file) => file.loadResult === "success")) {
      setStatusIcon("success");
    } else {
      setStatusIcon("danger");
    }
  }, [readFileData, currentFiles]);
  const removeFiles = (namesOfFilesToRemove) => {
    const newCurrentFiles = currentFiles.filter(
      (currentFile) =>
        !namesOfFilesToRemove.some((fileName) => fileName === currentFile.name)
    );
    setCurrentFiles(newCurrentFiles);
    const newReadFiles = readFileData.filter(
      (readFile) =>
        !namesOfFilesToRemove.some((fileName) => fileName === readFile.fileName)
    );
    setReadFileData(newReadFiles);
  };
  const updateCurrentFiles = async (files) => {
    // if (fileUploadShouldFail) {
    //   const corruptedFiles = files.map(file => ({
    //     ...file,
    //     lastModified: 'foo'
    //   }));
    //   setCurrentFiles(prevFiles => [...prevFiles, ...corruptedFiles]);
    // } else {
    setCurrentFiles((prevFiles) => [...prevFiles, ...files]);
    const uplData = new FormData();

    [...files].forEach((file, i) => {
      uplData.append(`file_path`, file);
    });
    try {
      await userService
        .uploadImage("quickAdminDocMangement/upload_file", uplData)
        .then((result) => {
          if (result.data.valid) {
            handleData(result.data.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
    // }
  };
  const handleFileDrop = (_event, droppedFiles) => {
    const currentFileNames = currentFiles.map((file) => file.name);
    const reUploads = droppedFiles.filter((droppedFile) =>
      currentFileNames.includes(droppedFile.name)
    );
    Promise.resolve()
      .then(() => removeFiles(reUploads.map((file) => file.name)))
      .then(() => updateCurrentFiles(droppedFiles));
  };
  const handleReadSuccess = (data, file) => {
    setReadFileData((prevReadFiles) => [
      ...prevReadFiles,
      {
        data,
        fileName: file.name,
        loadResult: "success",
      },
    ]);
  };
  const handleReadFail = (error, file) => {
    setReadFileData((prevReadFiles) => [
      ...prevReadFiles,
      {
        loadError: error,
        fileName: file.name,
        loadResult: "danger",
      },
    ]);
  };
  const createHelperText = (file) => {
    const fileResult = readFileData.find(
      (readFile) => readFile.fileName === file.name
    );
    if (fileResult?.loadError) {
      return (
        <HelperText isLiveRegion>
          <HelperTextItem variant={"error"}>
            {fileResult.loadError.toString()}
          </HelperTextItem>
        </HelperText>
      );
    }
  };
  console.log(data);
  const successfullyReadFileCount = readFileData.filter(
    (fileData) => fileData.loadResult === "success"
  ).length;
  return (
    <>
      <Row>
        <Col>
          <MultipleFileUpload
            onFileDrop={handleFileDrop}
            dropzoneProps={{
              accept: {
                "image/jpeg": [".jpg", ".jpeg"],
                "application/msword": [".doc"],
                "application/pdf": [".pdf"],
                "image/png": [".png"],
              },
            }}
            isHorizontal={false}
          >
            <MultipleFileUploadMain
              titleIcon={<UploadIcon />}
              titleText="Drag and drop files here"
              titleTextSeparator="or"
              infoText="Accepted file types: JPEG, Doc, PDF, PNG"
            />
            {/* {showStatus && (
              <MultipleFileUploadStatus
                statusToggleText={`${successfullyReadFileCount} of ${currentFiles.length} files uploaded`}
                statusToggleIcon={statusIcon}
                aria-label="Current uploads"
              >
                {currentFiles.map((file) => {
                  return (
                    <MultipleFileUploadStatusItem
                      file={file}
                      key={file.name}
                      onClearClick={() => removeFiles([file.name])}
                      // onReadSuccess={handleReadSuccess}
                      onReadFail={handleReadFail}
                      // progressHelperText={createHelperText(file)}
                    />
                  );
                })}
              </MultipleFileUploadStatus>
            )} */}
          </MultipleFileUpload>
        </Col>
        {/* Conditional div with document is uploaded */}
        <Col md={3} className="ps-0">
          <div className="upload_progress">
            {data.length > 0
              ? data.reverse().map((val, i) => (
                  <Row className="mb-2 bg-light p-2 rounded-2">
                    <Col xs={2}>
                      <div className="p-1">
                        <FaFile size={20} />
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-between">
                      <span
                        className="d-flex gap-2 align-items-center lh-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => window.open(`${nodeUrl}/${val.path}`)}
                      >
                        {val?.originalname}
                      </span>
                      <span className="d-flex gap-2 align-items-center lh-1">
                        {/* {val?.originalname} */}
                        <TiDelete
                          onClick={() => {
                            const arr = [...data];
                            arr.splice(i, 1);
                            deleteData(arr);
                          }}
                        />
                      </span>
                    </Col>
                  </Row>
                ))
              : ""}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default memo(UploadImage);
