// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { Tooltip } from "react-bootstrap";
import XLSX from "sheetjs-style";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment/moment";
import { jwtDecode } from "jwt-decode";
import { DateRangePicker } from "react-date-range";
import { FaCalendar } from "react-icons/fa";
const __DEV__ = window.location.hostname === "localhost";

// toast.configure();
// export const showSuccessToast = (msg) => {
//   toast.success(msg, {
//     position: "bottom-right",
//     autoClose: 10000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     theme: "colored",
//   });
// };
// export const showErrorToast = (msg) => {
//   toast.error(msg, {
//     position: "bottom-right",
//     autoClose: 10000,
//     hideProgressBar: false,
//     pauseOnHover: true,
//     draggable: true,
//     closeOnClick: true,
//     theme: "colored",
//   });
// };
export const authUser = (data) => {
  localStorage.setItem("auth", JSON.stringify(data));
};
export const getAuthUser = () => {
  return localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : null;
};
export const authShareUser = (data) => {
  sessionStorage.setItem("auth", JSON.stringify(data));
};
export const getAuthShareUser = () => {
  return sessionStorage.getItem("auth")
    ? JSON.parse(sessionStorage.getItem("auth"))
    : null;
};

// export const UserRight = () => {
//   const thirdmenu = useSelector((state) => state.common.userRightListThird);
// };
// export const buttonLoader = (loading, label, onClick, color) => {
//   return (
//     <Button
//       variant="contained"
//       color={color}
//       disabled={loading}
//       onClick={onClick}
//       disableElevation
//     >
//       {loading ? <i class="fas fa-circle-notch fa-spin"></i> : label}
//     </Button>
//   );
// };
// export const getBrowseUserRight = (response) => {
//   const pathArr = window.location.pathname.split("/").filter((x) => x !== "");
//   return response?.filter(
//     (x) => x.transaction_name.toLowerCase() === pathArr[2]
//   )[0];
// };

// export const getAutoValue = (key, arr, val) => {
//   const value = arr.filter((x) => x[key] === val);
//   return value && value.length > 0 ? value[0] : null;
// };
// export const debounce = (func, delay) => {
//   let debounceTimer;
//   return function () {
//     const context = this;
//     const args = arguments;
//     clearTimeout(debounceTimer);
//     debounceTimer = setTimeout(() => func.apply(context, args), delay);
//   };
// };
// export const getUserId = () => {
//   return localStorage.getItem("userId");
// };

// export const TableEditCheckbox = () => {
//   return (
//     <FormControlLabel label="Edit" control={<Checkbox color="primary" />} />
//   );
// };

// export const TableHideCheckbox = () => {
//   return (
//     <FormControlLabel control={<Checkbox color="primary" />} label="Hide" />
//   );
// };
// export const TableAllCheckbox = () => {
//   return (
//     <FormControlLabel control={<Checkbox color="primary" />} label="All" />
//   );
// };
// export const TableExportButton = ({ excelData, fileName }) => {
//   const fileType =
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".xlsx";
//   const excelExportButton = async () => {
//     const ws = XLSX.utils.json_to_sheet(excelData);
//     const excelBuffer = XLSX.write(
//       {
//         Sheets: { data: ws },
//         SheetNames: ["data"],
//       },
//       { bookType: "xlsx", type: "array" }
//     );
//     const data = new Blob([excelBuffer], { type: fileType });
//     saveAs(data, fileName + fileExtension);
//   };
//   return (
//     <Tooltip title="Export">
//       <Button
//         color="primary"
//         className="export_excel_btn"
//         disableElevation
//         variant="contained"
//         onClick={() => excelExportButton()}
//       >
//         <img src={excelIcon} className="excel_icons" alt="excel" />
//       </Button>
//     </Tooltip>
//   );
// };

//////////////////////////////////////////////// Demo Made for Export Button

// export const commonAPICallExportBtn = async (url, type, body) => {
//   const headers = {
//     authorization: getAuthUser() ? getAuthUser()?.token : "",
//   };
//   try {
//     const apiResponse = await axios({
//       method: type,
//       headers: headers,
//       url:
//         (__DEV__
//           ? process.env.REACT_APP_LOCAL_DOMAIN
//           : process.env.REACT_APP_PROD_DOMAIN) + url,
//       data: body,
//     });
//     if (apiResponse.status === 401) {
//     }
//     if (apiResponse.status === 200 || apiResponse.status === 500) {
//       return apiResponse.data;
//     } else {
//       console.log(apiResponse.data.message);
//     }
//   } catch (err) {
//     if (err.response.status === 401) {
//       localStorage.clear();
//       window.location.reload();
//     }
//     // showErrorToast(err);
//   }
// };

export const TableExportButton = ({ excelData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const excelExportButton = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const excelBuffer = XLSX.write(
      {
        Sheets: { data: ws },
        SheetNames: ["data"],
      },
      { bookType: "xlsx", type: "array" }
    );
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };
  return (
    // <Tooltip title="Export"><>
    <>
      <Button
        color="primary"
        size="sm"
        // className="export_excel_btn"
        // disableElevation
        variant="none"
        className="border text-success fs-6 d-flex gap-2 align-items-center customeBtnSM"
        onClick={() => excelExportButton()}
      >
        <img
          src={require("../assets/images/excel.png")}
          className="excel_icons"
          alt="excel"
        />
        Export
      </Button>
    </>
    // </Tooltip>
  );
};

export const DateRange = ({ onChange, date, label }) => {
  const [showPicker, setShowPicker] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dateFilterRange, setDatefilterRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const toggleDatePicker = () => {
    if (showPicker) {
      onChange(dateFilterRange);
    }
    setShowPicker(!showPicker);
  };
  const dateFilter = async (date) => {
    setDatefilterRange(date?.selection);
    setLoading(true);
  };
  useEffect(() => {
    if(date){
    setDatefilterRange({
      ...dateFilterRange,
      startDate: new Date(date.from_date),
      endDate: new Date(date.to_date),
    });
    setLoading(true);
  }
  }, [date]);

  return (
    <div className="custom-daterange-picker">
      <div
        className="custom-date-input d-flex align-items-center"
        onClick={toggleDatePicker}
      >
        <div className="custom-date-icon ">
          {/* <img className="img-fluid" src="/assets/images/calendar.png" alt="" /> */}
          <FaCalendar />
        </div>
        <span
          style={{
            fontSize: "0.8571428571428571rem",
            color: "rgba(0,0,0,0.6)",
          }}
        >
          {loading
            ? moment(dateFilterRange?.startDate).format("DD/MM/YYYY") +
              " - " +
              moment(dateFilterRange?.endDate).format("DD/MM/YYYY")
            : label}
        </span>
      </div>
      {showPicker && (
        <div className="custom-daterange-popup">
          <DateRangePicker
            ranges={[dateFilterRange]}
            onChange={dateFilter}
            minDate={new Date(new Date().getFullYear() - 25, 0, 1)}
            maxDate={new Date(new Date().getFullYear() + 25, 11, 31)}
          />
          <div className="text-end picker-toggler">
            <button
              className="btn btn-link "
              type="button"
              onClick={toggleDatePicker}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
