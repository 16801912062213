import config from "./config";
import axios from "axios";

import { getAuthUser } from "./components/common";

export const userService = {
  post,
  login,
  get,
  localpost,
  localget,
  jsonpost,
  uploadImage,
};

function post(apiEndpoint, payload, domain) {
  return axios
    .post(
      `${domain === "node" ? config.nodeUrl : config.apiUrl}` + apiEndpoint,
      payload,
      getOptions()
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}
function uploadImage(apiEndpoint, payload) {
  return axios
    .post(config.nodeUrl + apiEndpoint, payload, getOptionImage())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}
function jsonpost(apiEndpoint, payload) {
  return axios
    .post(config.jsonUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}

function localpost(apiEndpoint, payload) {
  return axios
    .post(config.nodeUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}

function localget(apiEndpoint) {
  return axios
    .get(config.nodeUrl + apiEndpoint, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}

function get(apiEndpoint, domain) {
  return axios
    .get(
      `${domain === "node" ? config.nodeUrl : config.apiUrl}` + apiEndpoint,
      getOptions()
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}
function login(apiEndpoint, payload) {
  return axios
    .post(config.nodeUrl + apiEndpoint, payload, getOptionsLogin())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}
function getOptions() {
  var options = {};
  options.headers = {
    Authorization: getAuthUser(), //the token is a variable which holds the token
    "Access-Control-Allow-Origin": "*",
  };
  return options;
}
function getOptionsLogin() {
  var options = {};
  options.headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
  };
}

function getOptionImage() {
  var options = {};
  options.headers = {
    Authorization: getAuthUser(),
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
  };
}
