import MainLayout from "../../../components/MainLayout";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import { FaFolder, FaTable } from "react-icons/fa";
import {
  AutoCompleteDropdown,
  AutoCompleteDropdownId,
} from "../../../components/autoCompleteDropdown";
import { NavLink } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { CommonController } from "../../../_redux/common.controller";
import { useEffect } from "react";
import { FaTableCells } from "react-icons/fa6";
import AddDocumentList from "../AddDocumentList";
export default function Uploads({filterData}) {
  const [fileList, setFileList] = useState([]);
  const [formdata, setformdata] = useState({
    document_category_id: null,
    document_category: "",
    document_type_id: null,
    document_type: "",
    field_name: "",
    fileType: [],
    field_value: "",
  });
  const getFileList = async (id) => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/file_list_filter",
        {
          document_type_id: id,
        },
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setFileList(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  useEffect(() => {
    if(formdata.document_category_id||formdata.field_value!==""){
      // filterData(formdata);
    }
  }, [formdata])
  useEffect(() => {
 getFileList(null);
  }, [])
  
  return (
    <MainLayout
      pageName="My Documents"
      // isOpen={() => setShow(true)}
      isAddBtnVisible={false}
    >
      <div className="white_box">
        <Col md={12}>
          <Form>
            <Row>
              <Col>
                <Row>
                  {" "}
                  <Col md={3} className="mb-2">
                    <AutoCompleteDropdown
                      url={"quickAdminDocMangement/category_list_filter"}
                      handleDataChange={(val) => {
                        setformdata({
                          ...formdata,
                          document_category_id: val.document_category_id,
                          document_category: val.document_category,
                          document_type_id:"",
                          field_name:"",
                          field_value:""
                        });
                      }}
                      labelName={"Document Category"}
                      valueInput={formdata.document_category}
                      objLevel={"document_category"}
                      // disable={!editData.type ? false : true}
                    />
                  </Col>
                  {formdata.document_category_id ? (
                    <Col md={3} className="mb-2">
                      <AutoCompleteDropdownId
                        url={"quickAdminDocMangement/type_list_filter"}
                        body={{
                          document_category_id: formdata.document_category_id,
                        }}
                        handleDataChange={(val) => {
                          setformdata({
                            ...formdata,
                            document_type_id: val.document_type_id,
                            document_type: val.document_type,
                            field_name:"",
                            field_value:""
                          });
                          getFileList(val.document_type_id);
                        }}
                        labelName={"Document Type"}
                        valueInput={formdata.document_type}
                        objLevel={"document_type"}
                        // disable={!editData.type ? false : true}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {formdata.document_type_id ? (
                    <Col className="mb-2">
                      <AutoCompleteDropdownId
                        url={"quickAdminDocMangement/field_list_name_filter"}
                        body={{
                          document_type_id: formdata.document_type_id,
                        }}
                        handleDataChange={(val) => {
                       setformdata({...formdata,field_name:val.field_name})
                        }}
                        labelName={"Field Name"}
                        valueInput={formdata.field_name}
                        objLevel={"field_name"}
                        // disable={!editData.type ? false : true}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {formdata.document_type_id ? ( <Col>
                    <TextField
                      type="text"
                      size="small"
                      label={"Field Value"}
                      variant="outlined"
                      fullWidth
                      onChange={(e) =>setformdata({...formdata,field_value:e.target.value})}
                      name="alphabetic"
                    />
                  </Col>):""}
                </Row>
                <Row className="mt-2">
                  {" "}
                 
                  <Col>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={fileList}
                      getOptionLabel={(option) => option.file_name}
                      // defaultValue={[top100Films[13]]}
                      value={formdata.fileType}
                      filterSelectedOptions
                      size="small"
                      onChange={(e, val) =>
                        setformdata({ ...formdata, fileType: val })
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="File List" />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              {/* <Col md={1} className="text-end">
                <Button variant="success">
                  {" "}
                  <FaRotate />
                </Button>
              </Col> */}
            </Row>
          </Form>
        </Col>
      </div>
      <Col md={12}>
        <Col
          md={12}
          className="d-flex gap-3 align-items-center"
          style={{ marginBottom: "15px", color: "#747474", fontSize: "12px" }}
        >
          <div className="upload_tab_buttons">
            {/* <NavLink
              className={`${({ isActive }) =>
                isActive ? "active" : ""} tabbtn`}
              to="/uploads/table"
            >
              <FaTableCells />
            </NavLink> */}
            <NavLink
              className={`${({ isActive }) =>
                isActive ? "active" : ""} tabbtn`}
              to="/dashboard-uploads/grid"
            >
              <FaTable />
            </NavLink>
            <NavLink
              className={`${({ isActive }) =>
                isActive ? "active" : ""} tabbtn`}
              to="/dashboard-uploads/folder"
            >
              <FaFolder />
            </NavLink>
          </div>
          <span>View</span>
        </Col>
        <Col md={12}>
          {/* <Outlet /> */}
         {formdata.document_category_id? <AddDocumentList filterData={formdata}/>:""}
        </Col>
      </Col>
    </MainLayout>
  );
}
