import { FaRotateRight } from "react-icons/fa6";

export default function Loader() {
  return (
    <>
      <div className="loader">
        <FaRotateRight />
        <span>Loading...</span>
      </div>
    </>
  );
}
