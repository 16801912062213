/* eslint-disable react/style-prop-object */
import MainLayout from "../../components/MainLayout";
import CommonDataGrid from "../../components/CommonDataGrid";
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Checkbox, FormControlLabel, Switch, TextField } from "@mui/material";
import { CommonController } from "../../_redux/common.controller";
import { FaPenToSquare } from "react-icons/fa6";
import { FaEye, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

export default function DocumentCategory() {
  // const handleCheckboxChange = (e, companyId) => {
  //   const { name, checked } = e.target;
  //   setCheckboxes({ ...checkboxes, [name]: checked });
  //   setCompanies((prevCompanies) =>
  //     prevCompanies.map((company) =>
  //       company.id === companyId
  //         ? { ...company, selected: !company.selected }
  //         : company
  //     )
  //   );
  // };
  const [checked, setChecked] = useState(true);
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    document_category_id: 0,
    document_category: "",
    company_id: [],
    disable: false,
    user_id: 0,
  });
  const handleClose = () => {
    setShow(false);
    setview(false);
    setFormData({
      document_category_id: 0,
      document_category: "",
      company_id: [],
      user_id: 0,
    });
  };
  const handleShow = () => setShow(true);
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add Doc Category",
  });
  const [view, setview] = useState(false);
  const [filter, setfilter] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);
  const [companyList, setcompanyList] = useState([]);
  const [excelRecord, setExcelRecords] = useState(0);
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [lengthyRemarks, setLengthyRemarks] = useState("");

  // const [checked, setChecked] = useState(true);

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/delete_document_category",
        {
          document_category_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Sucessfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sr No.",
        enableColumnFilter: false,
        minSize: 29,
        size: 52,
      },
      {
        accessorKey: "document_category",
        header: "Document Category",
        enableColumnFilter: false,
        minSize: 97,
        size: 347,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },
      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        size: 81,
        minSize: 144,
        Cell: ({ renderedCellValue, row }) => (
          <div className="text-center w-100">
            <FaPenToSquare
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.document_category_id,
                  type: false,
                  label: "Edit Doc Category",
                });
              }}
              style={{ cursor: "pointer", color: "blue" }}
            />
            <FaEye
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.document_category_id,
                  type: true,
                  label: "Preview Doc Category",
                });
              }}
              style={{
                // fontSize: "10px",
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />{" "}
            <FaTrash
              onClick={() => handleDeleteRow(row.original.document_category_id)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleChangetoggle = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };
  const handleChangecompany = (event) => {
    setFormData({ ...companyList, [event.target.name]: event.target.checked });
  };

  const CompanyList = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "auth/list_company_user",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setcompanyList(result.data);
            setFormData({ ...formData, company_id: result.data });
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };

  const submitDoc = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_document_category",
        formData,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setview(true);
            setupdateGrid(updateGrid + 1);
            setFormData({
              document_category: "",
              document_category_id: 0,
              disable: false,
              company_id: [],
            });

            handleClose();
            toast.success("New Document Category Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleEdit = (data) => {
    handleShow();
    const val = { ...data };
    val.valid = true;
    seteditData(val);

    try {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/preview_document_category",
        {
          document_category_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formData };
          for (let key in formData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setFormData(tempData);
        }
      });
    } catch (err) {
      console(err);
    }
  };

  const handleCountrySelect = (val) => {
    const arr = [...formData.company_id];
    return arr.filter((value) => value.company_id === val.company_id).length > 0
      ? true
      : false;
  };

  const handleBodyFilter = (data) => {
    setFormData(data);
    setfilter(false);
  };

  useEffect(() => {
    CompanyList();
  }, []);

  return (
    <MainLayout
      pageName="Document Category"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Add Document Category" });
        CompanyList();
      }}
      isAddBtnVisible={true}
    >
      <ToastContainer />

      <CommonDataGrid
        url={"quickAdminDocMangement/browse_document_category"}
        columns={gridColumn}
        body={""}
        // bodyData={() => setvalid(false)}
        jsonUpd={updateGrid}
        // browse_id={50}
        gridHeight={370}
        bodyData={handleBodyFilter}
        excelData={(data) => setExcelRecords(data)}
      />

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>{editData.label}</h4>
          <Form className="mt-3">
            <Row>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Document Category*"
                  variant="outlined"
                  className="w-100"
                  name="document_category"
                  value={formData.document_category}
                  onChange={handleChange}
                  // disabled={validSubmit}
                  disabled={view}
                />
              </Col>

              <Col md={4} className="mb-3">
                <FormControlLabel
                  control={<Switch size="small" />}
                  label="Disable"
                />
              </Col>
              <Col md={6} className="mb-3">
                {/* <FormControlLabel control={<Switch />} label="Disable" /> */}
                <Row>
                  {editData.valid ? (
                    <Col md={2} className="d-flex">
                      <div className="text-center">
                        <FormControlLabel
                          control={<Switch />}
                          label="Disable User"
                          onChange={handleChangetoggle}
                          checked={formData.disable}
                          name="disable"
                          disabled={view}
                        />
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
              <Col md={12} className="mb-3">
                <Table bordered size="sm" color="dark">
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyList.length > 0
                      ? companyList.map((val, ind) => {
                          return (
                            <tr key={val.company_id}>
                              <td className="text-left">{val.company_name}</td>
                              <td className="text-center">
                                <FormControlLabel
                                  control={<Checkbox size="small" />}
                                  onChange={(e) => {
                                    const valid = e.target.checked;
                                    if (valid) {
                                      const arr = [...formData.company_id];
                                      arr.push(val);
                                      setFormData({
                                        ...formData,
                                        company_id: arr,
                                      });
                                    } else {
                                      const arr = [...formData.company_id];
                                      const index = arr.findIndex(
                                        (value) =>
                                          value.company_id === val.company_id
                                      );
                                      arr.splice(index, 1);
                                      setFormData({
                                        ...formData,
                                        company_id: arr,
                                      });
                                    }
                                    // setFormData({...formData,company_id})
                                  }}
                                  checked={handleCountrySelect(val)}
                                  name={val.company_id}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </Table>
              </Col>
              <Col md={12}>
                <div className="w-100 mt-1 text-right">
                  {!editData.type ? (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={submitDoc}
                      className="me-3"
                    >
                      {editData.valid ? "Update" : "Save"}
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button variant="danger" size="sm" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
}
