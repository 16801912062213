import MainLayout from "../../components/MainLayout";
import CommonDataGrid from "../../components/CommonDataGrid";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  FormControlLabel,
  Switch,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { CommonController } from "../../_redux/common.controller";
import { useEffect } from "react";
import { FaPenToSquare } from "react-icons/fa6";
import { FaEye, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Companies() {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    company_name: "",
    address: "",
    city: "",
    pin: "",
    search: "",
    company_id: 0,
    state: "",
    country: "",
    pan: "",
    gstin: "",
    disable: false,
    user_id: 11,
  });
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add Company",
  });
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [lengthyRemarks, setLengthyRemarks] = useState("");
  const [validSubmit, setvalidSubmit] = useState(false);
  const handleClose = () => {
    setShow(false);

    setview(false);
    setFormData({
      company_name: "",
      address: "",
      city: "",
      pin: "",
      company_id: 0,
      state: "",
      country: "",
      pan: "",
      gstin: "",
      user_id: 0,
    });
  };
  const handleShow = () => setShow(true);
  const [valid, setvalid] = useState(false);
  const [view, setview] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);
  const [filter, setfilter] = useState(false);

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sr No",
        minSize: 32,
        size: 56,
      },
      {
        accessorKey: "datetime",
        header: "Date",
        width: 200,
        enableColumnFilter: false,
        minSize: 28,
        size: 81,
        Cell: ({ renderedCellValue, row }) => {
          return moment(renderedCellValue).format("DD/MM/yyyy");
        },
      },
      {
        accessorKey: "company_name",
        header: "Company Name",
        width: 400,
        enableColumnFilter: false,
        minSize: 92,
        size: 120,
        Cell: ({ renderedCellValue, row }) => (
					<div
						className="remark_lengthy"
						onClick={() => {
							setLengthyRemarks(renderedCellValue);
							setShowRemarksModal(true);
						}}
					>
						{renderedCellValue}
					</div>
				),
      },

      {
        accessorKey: "address",
        header: "Address",
        width: 200,
        enableColumnFilter: false,
        minSize: 48,
        size: 72,
        Cell: ({ renderedCellValue, row }) => (
					<div
						className="remark_lengthy"
						onClick={() => {
							setLengthyRemarks(renderedCellValue);
							setShowRemarksModal(true);
						}}
					>
						{renderedCellValue}
					</div>
				),
      },
      {
        accessorKey: "city",
        header: "City",
        width: 200,
        enableColumnFilter: false,
        minSize: 28,
        size: 54,
      },
      {
        accessorKey: "pin",
        header: "Pin",
        width: 200,
        enableColumnFilter: false,
        minSize: 21,
        size: 58,
      },
      {
        accessorKey: "state",
        header: "State",
        width: 200,
        enableColumnFilter: false,
        minSize: 31,
        size: 73,
      },

      {
        accessorKey: "country",
        header: "Country",
        width: 200,
        enableColumnFilter: false,
        minSize: 46,
        size: 67,
      },
      {
        accessorKey: "gstin",
        header: "GST IN",
        width: 200,
        enableColumnFilter: false,
        minSize: 40,
        size: 62,
      },
      {
        accessorKey: "pan",
        header: "Pan",
        width: 200,
        enableColumnFilter: false,
        minSize: 22,
        size: 53,
      },
      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        minSize: 37,
        size: 83,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FaPenToSquare
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.company_id,
                  type: false,
                  label: "Edit Company",
                });
              }}
              style={{cursor: "pointer", color: "blue" }}
            />
            <FaEye
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.company_id,
                  type: true,
                  label: "Preview Company",
                });
              }}
              style={{
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />{" "}
            <FaTrash
              onClick={() => handleDeleteRow(row.original.company_id)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    [formData]
  );

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminCompany/delete_company",
        {
          company_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Succesfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };

  const handleEdit = (data) => {
    handleShow();
    const val = { ...data };
    val.valid = true;
    seteditData(val);

    try {
      CommonController.commonApiCallFilter(
        "quickAdminCompany/preview_company",
        {
          company_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formData };
          // setvalidSubmit(true);

          for (let key in formData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setFormData(tempData);
        }
      });
    } catch (err) {
      console(err);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleChangetoggle = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };
  const submitComapny = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminCompany/insert_company",
        formData,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setview(true);
            setupdateGrid(updateGrid + 1);
            setFormData({ ...formData, company_id: result.data });
            setFormData({
              company_name: "",
              address: "",
              city: "",
              pin: "",
              state: "",
              country: "",
              pan: "",
              gstin: "",
            });
            handleClose();
            toast.success("New Company Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleBodyFilter = (data) => {
    setFormData(data);
    setfilter(false);
  };

  return (
    <MainLayout
      pageName="My Companies"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Add User" });
      }}
      isAddBtnVisible={true}
    >
      <CommonDataGrid
        url={"quickAdminCompany/browse_company"}
        columns={gridColumn}
        body={""}
        // bodyData={() => setvalid(false)}
        // jsonUpd={valid}
        // browse_id={50}
        jsonUpd={updateGrid}
        gridHeight={370}
        bodyData={handleBodyFilter}
      />

      <ToastContainer />

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>Add New Company</h4>
          <Form className="mt-3">
            <Row>
              <Col md={12} className="mb-3">
                <TextField
                  size="small"
                  label="Enter company name*"
                  variant="outlined"
                  className="w-100"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={12} className="mb-3">
                <Row>
                  <Col md={6}>
                    <TextareaAutosize
                      size="small"
                      className="w-100 h-100"
                      placeholder="Enter complete address"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      disabled={view}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <TextField
                        size="small"
                        label="City*"
                        variant="outlined"
                        className="w-100"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        disabled={view}
                      />
                    </div>

                    <TextField
                      size="small"
                      label="Pin*"
                      variant="outlined"
                      className="w-100"
                      name="pin"
                      value={formData.pin}
                      onChange={handleChange}
                      disabled={view}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="State*"
                  variant="outlined"
                  className="w-100"
                  value={formData.state}
                  name="state"
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Country*"
                  variant="outlined"
                  className="w-100"
                  value={formData.country}
                  name="country"
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="PAN No"
                  variant="outlined"
                  className="w-100"
                  value={formData.pan}
                  name="pan"
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="GSTIN"
                  variant="outlined"
                  className="w-100"
                  value={formData.gstin}
                  name="gstin"
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col>
                {editData.valid ? (
                  <Col md={2} className="d-flex">
                    <div className="text-center">
                      <FormControlLabel
                        control={<Switch />}
                        label="Disable User"
                        onChange={handleChangetoggle}
                        checked={formData.disable}
                        name="disable"
                        disabled={view}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Col>
              <Col md={12}>
                <div className="w-100 mt-1 text-right">
                  {!editData.type ? (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={submitComapny}
                      className="me-3"
                    >
                      {editData.valid ? "Update" : "Save"}
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button variant="danger" size="sm" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
}
