import React, { useState, useEffect } from "react";
// import { CommonController } from "../_redux/controller/common.controller";
import { Autocomplete, TextField } from "@mui/material";
import { CommonController } from "../_redux/common.controller";

export const AutoCompleteDropdown = ({
  url,
  handleDataChange,
  labelName,
  valueInput,
  objLevel,
  objFormDataNameId,
  disable,
  id,
}) => {
  const [listData, setlistData] = useState([]);
  const dropDownList = async () => {
    await CommonController.commonApiCallFilter(url, "", "post", "node").then(
      (data) => {
        if (data.valid) {
          setlistData(data.data);
        }
      }
    );
  };

  const handleAutoChange = (value) => {
    handleDataChange(value);
  };
  useEffect(() => {
    dropDownList();
  }, []);
  return (
    <Autocomplete
      size="small"
      id={id}
      options={listData}
      disabled={disable}
      getOptionLabel={(option) => option[objLevel]}
      onPaste={() => false}
      fullWidth
      value={{ [objLevel]: valueInput }}
      onChange={(e, value) => {
        if (value) {
          handleAutoChange(value);
        }
      }}
      variant="outlined"
      renderInput={(params) => (
        <TextField {...params} label={labelName} variant="outlined" />
      )}
    />
  );
};
export const AutoCompleteDropdownId = ({
  url,
  body,
  handleDataChange,
  labelName,
  valueInput,
  objLevel,
}) => {
  const [listData, setlistData] = useState([]);
  const [fieldValue, setfieldValue] = useState({ [objLevel]: "" });
  const dropDownList = async () => {
    await CommonController.commonApiCallFilter(url, body, "post", "node").then(
      (data) => {
        if (data.valid) {
          setlistData(data.data);
          if (valueInput !== "") {
            const filterData = data.data.filter(
              (val) => val[objLevel] === valueInput
            );
            setfieldValue(filterData[0]);
          } else {
            const objkey = Object.keys(data.data[0]);
            let obj = {};
            objkey.forEach((val) => {
              obj[val] = "";
            });
            setfieldValue(obj);
          }
        } else {
          setfieldValue({ [objLevel]: "" });
        }
      }
    );
  };
  const handleAutoChange = (value) => {
    handleDataChange(value);
    setfieldValue(value);
  };
  useEffect(() => {
    dropDownList();
  }, [body]);
  return (
    <Autocomplete
      size="small"
      options={listData}
      getOptionLabel={(option) => option[objLevel]}
      fullWidth
      value={fieldValue}
      onChange={(e, value) => handleAutoChange(value)}
      variant="outlined"
      renderInput={(params) => (
        <TextField {...params} label={labelName} variant="outlined" />
      )}
    />
  );
};
