// import { userService } from "../../services";

import { userService } from "../services";

export const CommonController = {
  commonApiCall,
  commonApiCallFilter,
  commonBrowseApiCallNew,
};

async function commonBrowseApiCallNew(url, body, params, domain) {
  try {
    let apiEndpoint = `${url}?page_number=${params.page_number}&page_size=${params.page_size}&sort_column=${params.sort_column}&sort_order=${params.sort_order}`;
    let response = await userService.post(apiEndpoint, body, domain);
    if (response) {
      return response.data;
    } else {
      if (response.data.status !== 500) {
        alert(response.data);
      }
      return null;
    }
  } catch (error) {
    console.log("error", error);
    return null;
  }
}
async function commonApiCall(url, params, body, domain) {
  try {
    let apiEndpoint = `${url}?filter_value=${params.filter_value}&page_number=${params.pageNo}&page_size=${params.pageSize}&sort_column=${params.sort_column}&sort_order=${params.sort_order}`;
    let response = await userService.post(apiEndpoint, body, domain);
    if (response) {
      return response.data;
    } else {
      if (response.data.status !== 500) {
        alert(response.data);
      }
      return null;
    }
  } catch (error) {
    console.log("error", error);
    return null;
  }
}
async function commonApiCallFilter(url, body, type = "post", domain = "") {
  try {
    let apiEndpoint = `${url}`;
    let response;
    if (type === "post") {
      response = await userService.post(apiEndpoint, body, domain);
    } else {
      let queryString = Object.keys(body)
        .map((key) => key + "=" + body[key])
        .join("&");
      let _url = url + "?" + queryString;
      response = await userService.get(_url, domain);
    }
    if (response) {
      return response.data;
    } else {
      if (response.data.status !== 500) {
        alert(response.data);
      }
      return null;
    }
  } catch (error) {
    console.log("error", error);
    return null;
  }
}
