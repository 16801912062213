import { Col, Container, Row } from "react-bootstrap";
import classes from "./Authentication.module.css";
import logo from "../../assets/images/black-logo.png";

export default function LoginLayout({ children }) {
  return (
    <div className={classes.login_main}>
      <Container fluid>
        <Container>
          <Row>
            <Col md={5} className="m-auto">
              <div className={`${classes.login__box} shadow`}>
                <Row>
                  <Col md={12} className="text-center">
                    <img src={logo} alt="" className={classes.logo} />
                  </Col>
                  <Col md={12}>{children}</Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
