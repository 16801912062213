import { Button, Col, Container, DropdownButton, Row } from "react-bootstrap";
import logo from "../assets/images/white-logo.png";
import {
  FaBell,
  FaCog,
  FaInfo,
  FaPowerOff,
  FaUserAlt,
  FaUserCircle,
} from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import classes from "./Header.module.css";
import { Link } from "react-router-dom";
import HeaderTime from "./HeaderTime";
import { AutoCompleteDropdown } from "../components/autoCompleteDropdown";
import { CommonController } from "../_redux/common.controller";
import React, { useState, useEffect } from "react";
import { getAuthUser } from "./common";
export default function Header() {
  const [company, setcompany] = useState({
    user_id: null,
    company_name: "",
    company_id: "",
  });
  const user = jwtDecode(JSON.parse(localStorage.getItem("auth")));
  const logOut = () => {
    localStorage.removeItem("auth");
    window.location.reload();
  };
  const submitDoc = async (val) => {
    try {
      await CommonController.commonApiCallFilter(
        "auth/update_company_user",
        { company_id: val.company_id },
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            window.location.reload();
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const selectCompanyList = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "auth/select_company_user",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setcompany(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  useEffect(() => {
    selectCompanyList();
  }, []);
  return (
    <header>
      <Container fluid>
        <div className={classes.top__header}>
          <Row className="align-item-center">
            <Col>
              <img src={logo} className="logo" alt="" />
            </Col>
            <Col className="d-flex align-items-center col-3">
              <div className="header_select w-100 d-flex align-items-center gap-2">
                <label className="text-white ">Select Company</label>
                <div className="flex1">
                  <AutoCompleteDropdown
                    url={"auth/list_company_user"}
                    handleDataChange={(val) => {
                      setcompany(val);
                      submitDoc(val);
                    }}
                    // labelName={"Select Company"}
                    valueInput={company.company_name}
                    objLevel={"company_name"}
                  />
                </div>
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-end gap-5">
              <HeaderTime />
              <DropdownButton
                variant="none"
                title={
                  <div
                    className="d-flex align-items-center text-white fw-bold gap-2"
                    style={{ fontSize: "11px" }}
                  >
                    {user?.user?.first_name.toUpperCase()}
                    <FaUserCircle color="#fff" size="18px" />
                  </div>
                }
                className={classes.user_profile_menu}
              >
                <li>
                  <Link to="/profile">
                    <FaUserAlt />
                    Profile
                  </Link>
                </li>
                <li>
                  <Link to="/notifications">
                    <FaBell />
                    Notifications
                  </Link>
                </li>
                {/* <li>
                  <Link to="/help">
                    <FaInfo />
                    Help
                    </li>
                <li>
                  <Link to="/settings">
                    <FaCog />
                    </Link>
                    Settings
                  </Link>
                </li> */}
                <li>
                  <Button variant="none" size="sm" onClick={logOut}>
                    <FaPowerOff />
                    Logout
                  </Button>
                </li>
              </DropdownButton>
            </Col>
          </Row>
        </div>
      </Container>
    </header>
  );
}
