import MainLayout from "../../components/MainLayout";
import React, { useEffect } from "react";
import CommonDataGrid from "../../components/CommonDataGrid";
import { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { AutoCompleteDropdown } from "../../components/autoCompleteDropdown";
import { CommonController } from "../../_redux/common.controller";
import { FaEye, FaTrash } from "react-icons/fa";
import { FaPenToSquare } from "react-icons/fa6";
import SharingDashboard from "../home/SharingDashboard";
import { ToastContainer, toast } from "react-toastify";

export default function DocumentType() {
  const [show, setShow] = useState(false);
  const [companyList, setcompanyList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [filter, setfilter] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);

  const handleShow = () => setShow(true);
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add File Category",
  });
  const [documentTypeList, setDocumentTypeList] = useState([
    {
      field_name: "",
      field_type: "",
      sr_no: 1,
    },
  ]);
  const [generateList, setgenerateList] = useState([
    // {
    //   value: "",
    //   sr_no:null,
    //   id:1
    // },
  ]);
  const date = new Date();
  const [formdata, setformdata] = useState({
    document_type_id: 0,
    ID: date.valueOf(),
    document_category_id: null,
    document_category: "",
    document_type: "",
    remarks: "",
    mandatory: false,
    dcoParameter: [],
    docParameterList: [],
    fileType: [],
    company_id: [],
    disable: false,
  });

  const TypeOptions = [
    "alphabetic",
    "numeric",
    "date",
    "daterange",
    "list",
    "currency",
  ];
  const addDocTypeList = () => {
    const values = [...documentTypeList];
    values.push({
      field_name: "",
      field_type: "",
      sr_no: documentTypeList.length + 1,
    });
    setDocumentTypeList(values);
  };
  const addGenerateList = () => {
    const values = [...generateList];
    const idList = values.filter(
      (val) => val.sr_no === showGenerateListModal.sr_no
    );
    values.push({
      value: "",
      id: idList.length + 1,
      sr_no: showGenerateListModal.sr_no,
    });
    setgenerateList(values);
  };
  const [showGenerateListModal, setshowGenerateListModal] = useState({
    valid: false,
    sr_no: 0,
  });
  const closeGenerateListModal = () => {
    setshowGenerateListModal({ valid: false, sr_no: 0 });
  };
  const handleClose = () => window.location.reload();
  const handleRemoveGeneralList = (index) => {
    const updatedList = [...generateList];
    updatedList.splice(index, 1);
    setgenerateList(updatedList);
  };
  const handleRemoveDocumentType = (index, val) => {
    const updatedList = [...documentTypeList];
    const indexOfGEnerallist = generateList.findIndex(
      (element) => element.sr_no === val.sr_no
    );
    updatedList.splice(index, 1);
    setDocumentTypeList(updatedList);
    if (indexOfGEnerallist > -1) {
      const updatedGenList = [...generateList];
      updatedGenList.splice(indexOfGEnerallist, 1);
      setgenerateList(updatedGenList);
    }
  };
  const CompanyList = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "auth/list_company_user",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setcompanyList(result.data);
            setformdata({ ...formdata, company_id: result.data });
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const getFileList = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/file_list",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setFileList(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [lengthyRemarks, setLengthyRemarks] = useState("");
  const handleChange = (event) => {
    setformdata({ ...formdata, [event.target.name]: event.target.value });
  };
  const handleCheckBox = (event) => {
    setformdata({ ...formdata, [event.target.name]: event.target.checked });
  };
  const [view, setview] = useState(false);

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/delete_document_type",
        {
          document_type_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Sucessfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sr No.",
        enableColumnFilter: false,
        minSize: 29,
        size: 47,
      },
      {
        accessorKey: "ID",
        header: "ID",
        // width: 260,
        enableColumnFilter: false,
        minSize: 12,
        size: 64,
      },
      {
        accessorKey: "document_category",
        header: "Document Category",
        enableColumnFilter: false,
        minSize: 97,
        size: 179,
      },
      {
        accessorKey: "document_type",
        header: "Document type",
        enableColumnFilter: false,
        minSize: 74,
        size: 224,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },
      // {
      //   accessorKey: "date_of_receiving",
      //   header: "Date of receiving",
      //   width: 200,
      //   enableColumnFilter: false,
      //   minSize: 73,
      //   Cell: ({ renderedCellValue, row }) => (
      //     <div>
      //       <FormControlLabel
      //         control={<Checkbox />}
      //         // label="Enable Login"
      //         // onChange={handleChangetoggle}
      //         checked={renderedCellValue}
      //         name="enable_login"
      //       />
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "date_of_document",
      //   header: "Date of document",
      //   width: 200,
      //   enableColumnFilter: false,
      //   Cell: ({ renderedCellValue, row }) => (
      //     <div>
      //       <FormControlLabel
      //         control={<Checkbox />}
      //         // label="Enable Login"
      //         // onChange={handleChangetoggle}
      //         checked={renderedCellValue}
      //         name="enable_login"
      //       />
      //     </div>
      //   ),
      // },
      {
        accessorKey: "disable",
        header: "Disable",
        minSize: 36,
        size: 87,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => (
          <div className="text-center w-100">
            <FormControlLabel
              control={<Checkbox size="sm" />}
              // label="Enable Login"
              // onChange={handleChangetoggle}
              checked={renderedCellValue}
              name="enable_login"
            />
          </div>
        ),
      },
      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        minSize: 31,
        size: 73,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FaPenToSquare
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.document_type_id,
                  type: false,
                  label: "Edit File Category",
                });
              }}
              style={{ cursor: "pointer", color: "blue" }}
            />
            <FaEye
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.document_type_id,
                  type: true,
                  label: "Preview File Category",
                });
              }}
              style={{
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />{" "}
            <FaTrash
              onClick={() => handleDeleteRow(row.original.document_type_id)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    []
  );
  const handleCountrySelect = (val) => {
    const arr = [...formdata.company_id];
    return arr.filter((value) => value.company_id === val.company_id).length > 0
      ? true
      : false;
  };
  const submitDoc = async () => {
    try {
      const obj = { ...formdata };
      obj.dcoParameter = documentTypeList;
      obj.docParameterList = generateList;
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_doc_type",
        obj,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            // // showSuccessToast("Success");
            // // setview(true);
            // // setupdateGrid(updateGrid + 1);
            // // setFormData({ ...formData, file_category_id: result.data });
            // // setFormData({
            // //   file_category: "",
            // //   file_category_id: 0,
            // // });
            // handleClose();
            setupdateGrid(updateGrid + 1);

            window.location.reload();
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const handleEdit = (data) => {
    handleShow();
    const val = { ...data };
    val.valid = true;
    seteditData(val);
    try {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/previ_doc_type",
        {
          document_type_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formdata };
          // setvalidSubmit(true);

          for (let key in formdata) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setformdata(tempData);
          setDocumentTypeList(data.dcoParameter);
          setgenerateList(data.docParameterList);
        }
      });
    } catch (err) {
      console(err);
    }
  };
  const handleBodyFilter = (data) => {
    setformdata(data);
    setfilter(false);
  };
  useEffect(() => {
    CompanyList();
    getFileList();
  }, []);
  return (
    <MainLayout
      pageName="Document Types"
      isOpen={() => setShow(true)}
      isAddBtnVisible={true}
    >
      <CommonDataGrid
        url={"quickAdminDocMangement/browse_doc_type"}
        columns={gridColumn}
        body={""}
        // bodyData={() => setvalid(false)}
        jsonUpd={updateGrid}
        gridHeight={370}
        bodyData={handleBodyFilter}
        // jsonUpd={0}
      />

      <Modal
        show={showGenerateListModal.valid}
        onHide={closeGenerateListModal}
        centered
        backdropClassName="overpopup"
        className="generatelistpopups"
        size="md"
      >
        <Modal.Body>
          <h5 className="fw-bold mb-3">Document Type</h5>
          <Table bordered>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>List Name</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {generateList.map((el, i) => {
                if (el.sr_no === showGenerateListModal.sr_no) {
                  return (
                    <tr>
                      <td>{el.id}</td>
                      <td>
                        <TextField
                          id="list"
                          size="small"
                          label=""
                          value={el.value}
                          onChange={(e) => {
                            const arr = [...generateList];
                            arr[i].value = e.target.value;
                            arr[i].sr_no = showGenerateListModal.sr_no;
                            setgenerateList(arr);
                          }}
                          variant="outlined"
                          className="w-100"
                        />
                      </td>
                      <td>
                        <Button
                          size="sm"
                          variant="none"
                          color="red"
                          onClick={() => handleRemoveGeneralList(i)}
                        >
                          x
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <Button size="sm" variant="warning" onClick={addGenerateList}>
            Add +
          </Button>

          <Button
            size="sm"
            className="m-2"
            variant="danger"
            onClick={closeGenerateListModal}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={show}
        // onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>Document Type</h4>
          <Form className="mt-3">
            <Row>
              <Col md={10}>
                <Row>
                  <Col md={4} className="mb-3">
                    <TextField
                      id="demo"
                      disabled
                      size="small"
                      label="ID*"
                      variant="outlined"
                      className="w-100"
                      value={formdata.ID}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <AutoCompleteDropdown
                      url={"quickAdminDocMangement/list_doc_category"}
                      handleDataChange={(val) => {
                        setformdata({
                          ...formdata,
                          document_category_id: val.document_category_id,
                          document_category: val.document_category,
                        });
                      }}
                      labelName={"Document Category*"}
                      valueInput={formdata.document_category}
                      objLevel={"document_category"}
                      disable={!editData.type ? false : true}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <TextField
                      id="demo"
                      size="small"
                      label="Enter Document Type*"
                      value={formdata.document_type}
                      onChange={handleChange}
                      name="document_type"
                      variant="outlined"
                      disabled={!editData.type ? false : true}
                      className="w-100"
                    />
                  </Col>
                  <Col md={12}>
                    <Table bordered size="sm">
                      <tbody>
                        <tr></tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={12}>
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Field Name</th>
                          <th>Types</th>
                          <th> </th>
                          <th>Mandatory </th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentTypeList.map((el, i) => (
                          <tr key={el.sr_no}>
                            <td>{el.sr_no}</td>
                            <td>
                              <TextField
                                disabled={!editData.type ? false : true}
                                id="demo"
                                size="small"
                                variant="outlined"
                                className="w-100"
                                value={el.field_name}
                                onChange={(e) => {
                                  const arr = [...documentTypeList];
                                  arr[i].field_name = e.target.value;
                                  setDocumentTypeList(arr);
                                }}
                                name="field_name"
                              />
                            </td>
                            <td>
                              <Autocomplete
                                options={TypeOptions}
                                value={el.field_type}
                                // disabled={!editData.type ? false : true}
                                getOptionLabel={(option) =>
                                  option.toUpperCase()
                                }
                                onChange={(event, newValue) => {
                                  const arr = [...documentTypeList];
                                  arr[i].field_type = newValue;
                                  setDocumentTypeList(arr);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Field Type"
                                    name="field_type"
                                  />
                                )}
                              />
                              {/* </FormControl> */}
                            </td>
                            <td>
                              <Button
                                size="sm"
                                variant="primary"
                                disabled={
                                  el.field_type === "list" ? false : true
                                }
                                style={{ minWidth: "130px" }}
                                onClick={() => {
                                  setshowGenerateListModal({
                                    valid: true,
                                    sr_no: el.sr_no,
                                  });
                                  const validgeneralList = generateList.find(
                                    (val) => val.sr_no === el.sr_no
                                  )
                                    ? true
                                    : false;
                                  if (!validgeneralList)
                                    setgenerateList((val) => [
                                      ...val,
                                      {
                                        value: "",
                                        sr_no: el.sr_no,
                                        id: 1,
                                      },
                                    ]);
                                }}
                              >
                                Generate List
                              </Button>
                            </td>
                            <td>
                              <FormControlLabel
                                control={<Checkbox />}
                                disabled={!editData.type ? false : true}
                                // label="Mandatory"
                                onChange={(event) => {
                                  const arr = [...documentTypeList];
                                  arr[i].mandatory = event.target.checked;
                                  setDocumentTypeList(arr);
                                }}
                                checked={el.mandatory}
                                name="mandatory"
                              />
                            </td>
                            <td>
                              <Button
                                size="sm"
                                variant="none"
                                color="red"
                                onClick={() => handleRemoveDocumentType(i, el)}
                                disabled={!editData.type ? false : true}
                              >
                                x
                              </Button>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}>
                            <div className="text-end">
                              <Button
                                size="sm"
                                className="text-white"
                                variant="warning"
                                disabled={!editData.type ? false : true}
                                onClick={addDocTypeList}
                              >
                                Add +
                              </Button>
                            </div>
                            {/* <div className="text-end">
                              <SharingDashboard />
                            </div> */}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col md={2}>
                <Row>
                  <Table bordered size="sm" color="dark">
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyList.length > 0
                        ? companyList.map((val, ind) => {
                            return (
                              <tr key={val.company_id}>
                                <td className="text-left">
                                  {val.company_name}
                                </td>
                                <td className="text-center">
                                  <FormControlLabel
                                    control={<Checkbox />}
                                    disabled={!editData.type ? false : true}
                                    onChange={(e) => {
                                      const valid = e.target.checked;
                                      if (valid) {
                                        const arr = [...formdata.company_id];
                                        arr.push(val);
                                        setformdata({
                                          ...formdata,
                                          company_id: arr,
                                        });
                                      } else {
                                        const arr = [...formdata.company_id];
                                        const index = arr.findIndex(
                                          (value) =>
                                            value.company_id === val.company_id
                                        );
                                        arr.splice(index, 1);
                                        setformdata({
                                          ...formdata,
                                          company_id: arr,
                                        });
                                      }
                                      // setformdata({...formdata,company_id})
                                    }}
                                    checked={handleCountrySelect(val)}
                                    name={val.company_id}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                </Row>
              </Col>
              <Col md={12} className=" mt-3">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  disabled={!editData.type ? false : true}
                  options={fileList}
                  getOptionLabel={(option) => option.file_name}
                  // defaultValue={[top100Films[13]]}
                  value={formdata.fileType}
                  filterSelectedOptions
                  size="small"
                  onChange={(e, val) =>
                    setformdata({ ...formdata, fileType: val })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tag Files"
                      placeholder="Favorites"
                    />
                  )}
                />
              </Col>
              <Col md={12} className=" mt-3">
                <TextField
                  id="remarks"
                  size="small"
                  label="Remarks"
                  value={formdata.remarks}
                  onChange={handleChange}
                  name="remarks"
                  variant="outlined"
                  disabled={!editData.type ? false : true}
                  className="w-100"
                />
              </Col>
              {/* disable button  */}
              {formdata.document_type_id ? (
                <Col md={4}>
                  <FormControlLabel
                    control={<Switch />}
                    label="Disable"
                    disabled={!editData.type ? false : true}
                    onChange={handleCheckBox}
                    checked={formdata.disable}
                    name="disable"
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {!editData.type ? (
            <Button
              variant="success"
              size="sm"
              onClick={submitDoc}
              className="me-3"
            >
              {editData.valid ? "Update" : "Save"}
            </Button>
          ) : (
            ""
          )}
          <Button variant="danger" size="sm" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
}
