import MainLayout from "../../components/MainLayout";
import CommonDataGrid from "../../components/CommonDataGrid";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Autocomplete,
  FormControlLabel,
  Switch,
  TextField,
  TextareaAutosize,
} from "@mui/material";
// import { top100Films } from "../../constants/DummyDropdown";
import { CommonController } from "../../_redux/common.controller";
import { FaPenToSquare, FaEye, FaTrash } from "react-icons/fa6";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";

export default function Users() {
  const [show, setShow] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);
  const [formData, setFormData] = useState({
    user_id: 0,
    emp_code: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    ccode: "",
    pin: "",
    state: "",
    country: "",
    companyName: "",
    disable: false,
    enable_login: true,
    userCompany: [],
  });

  const handleClose = () => {
    setShow(false);
    setview(false);
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      ccode: "",
      pin: "",
      state: "",
      country: "",
      user_company: "",
      user_id: 0,
      userCompany: [],
    });
  };
  const handleShow = () => setShow(true);
  const [filter, setfilter] = useState(false);

  const countries = [
    { code: "+971", label: "Dubai" },
    { code: "+91", label: "India" },
  ];
  const [view, setview] = useState(false);
  const [editData, seteditData] = useState({ valid: false, label: "Add User" });
  const [companyList, setcompanyList] = useState([]);
  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sr No",
        minSize: 32,
        size: 56,
      },
      {
        accessorKey: "datetime",
        header: "Date",
        width: 200,
        enableColumnFilter: false,
        minSize: 28,
        size: 112,
        Cell: ({ renderedCellValue, row }) => {
          return moment(renderedCellValue).format("DD/MM/yyyy");
        },
      },
      {
        accessorKey: "first_name",
        header: "First Name",
        width: 400,
        enableColumnFilter: false,
        minSize: 63,
        size: 85,
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
        width: 400,
        enableColumnFilter: false,
        minSize: 62,
        size: 85,
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        width: 400,
        enableColumnFilter: false,
        minSize: 39,
        size: 85,
      },
      {
        accessorKey: "email",
        header: "Email",
        width: 400,
        enableColumnFilter: false,
        minSize: 32,
        size:85,
      },
      {
        accessorKey: "address",
        header: "Address",
        width: 400,
        enableColumnFilter: false,
        minSize: 48,
        size: 85,
      },
      {
        accessorKey: "city",
        header: "City",
        width: 400,
        enableColumnFilter: false,
        minSize: 28,
        size: 85,
      },
      {
        accessorKey: "state",
        header: "State",
        width: 400,
        enableColumnFilter: false,
        minSize: 31,
        size: 85,
      },
      {
        accessorKey: "country",
        header: "Country",
        width: 400,
        enableColumnFilter: false,
        minSize: 46,
        size: 70,
      },

      {
        accessorKey: "pin",
        header: "Pin",
        width: 400,
        enableColumnFilter: false,
        minSize: 21,
        size: 64,
      },

      {
        accessorKey: "disable",
        header: "Disable",
        enableColumnFilter: false,
        minSize: 44,
        size: 72,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FormControlLabel
              control={<Switch size="small" />}
              // label="Disable User"
              onChange={handleChangetoggle}
              checked={renderedCellValue}
              name="disable"
              size="small"
            />
          </div>
        ),
      },
      {
        accessorKey: "enable_login",
        header: "Enable Login",
        enableColumnFilter: false,
        minSize: 75,
        size: 111,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FormControlLabel
              control={<Switch size="small" />}
              // label="Enable Login"
              onChange={handleChangetoggle}
              checked={renderedCellValue}
              name="enable_login"
            />
          </div>
        ),
      },

      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        minSize: 37,
        size: 85,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FaPenToSquare
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.user_id,
                  type: false,
                  label: "Edit User",
                });
              }}
              style={{ cursor: "pointer", color: "blue" }}
            />

            <FaEye
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.user_id,
                  label: "Preview User",
                  type: true,
                });
              }}
              style={{
               
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />

            <FaTrash
              onClick={() => handleDeleteRow(row.original.user_id)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    []
  );
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleChangetoggle = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };
  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminUser/delete_user",
        {
          user_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Succesfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };
  const cpList = (id) => {
    CommonController.commonApiCallFilter(
      "quickAdminUser/list_company",
      {
        user_id: id,
      },
      "post",
      "node"
    ).then((data) => {
      if (data.valid) {
        setcompanyList(data.data);
      }
    });
  };
  const handleEdit = (data) => {
    handleShow();
    const val = { ...data };
    val.valid = true;
    seteditData(val);
    try {
      CommonController.commonApiCallFilter(
        "quickAdminUser/preview_user",
        {
          user_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formData };
          // setvalidSubmit(true);

          for (let key in formData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setFormData(tempData);
        }
      });
    } catch (err) {
      console(err);
    }
  };
  const submitDoc = async () => {
    try {
      const body = { ...formData };
      body.rightsData = [];
      await CommonController.commonApiCallFilter(
        "quickAdminUser/insert_user",
        body,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setview(true);
            setupdateGrid(updateGrid + 1);
            setFormData({
              first_name: "",
              last_name: "",
              email: "",
              mobile: "",
              address: "",
              city: "",
              ccode: "",
              pin: "",
              state: "",
              country: "",
              disable: false,
              emp_code: "",
            });
            handleClose();
            toast.success("New User Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const handleBodyFilter = (data) => {
    setFormData(data);
    setfilter(false);
  };

  useEffect((type) => {
    cpList();
  }, []);
  return (
    <MainLayout
      pageName="Users"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Add User" });
      }}
      isAddBtnVisible={true}
    >
      <CommonDataGrid
        url={"quickAdminUser/browse_user"}
        columns={gridColumn}
        body={""}
        // bodyData={() => setvalid(false)}
        jsonUpd={updateGrid}
        // browse_id={50}
        gridHeight={370}
        bodyData={handleBodyFilter}
      />
      <ToastContainer />

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>{editData.label}</h4>
          <Form className="mt-3">
            <Row>
              {/* <ToastContainer />; */}
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="First Name*"
                  variant="outlined"
                  fullWidth
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Last Name*"
                  variant="outlined"
                  fullWidth
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3 d-flex gap-1 align-items-center">
                <Autocomplete
                  size="small"
                  id="country-select-demo"
                  style={{ width: "150px" }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.code}
                  value={{ code: formData.ccode, label: formData.label }}
                  onChange={(e, value) => {
                    setFormData({
                      ...formData,
                      ccode: value.code,
                      label: formData.label,
                    });
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                    />
                  )}
                />

                <TextField
                  type="number"
                  size="small"
                  label="Mobile*"
                  variant="outlined"
                  className="w-100"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Email*"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              {/* <Col md={6}>
                mohit
                <PhoneInput
                  value={formData.phone}
                  onChange={(phone) =>
                    setFormData({ ...formData, phone: phone })
                  }
                  placeholder="Phone number"
                />
              </Col> */}
              <Col md={12} className="mb-3">
                <Row>
                  <Col md={6} className="mb-3 mb-md-0">
                    <TextareaAutosize
                      name="address"
                      size="small"
                      value={formData.address}
                      onChange={handleChange}
                      className="w-100 h-100"
                      placeholder="Enter complete address"
                      disabled={view}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <TextField
                        size="small"
                        label="City"
                        variant="outlined"
                        fullWidth
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        disabled={view}
                      />
                    </div>

                    <TextField
                      size="small"
                      label="Pin"
                      variant="outlined"
                      fullWidth
                      name="pin"
                      value={formData.pin}
                      onChange={handleChange}
                      disabled={view}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="State*"
                  variant="outlined"
                  fullWidth
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Country*"
                  variant="outlined"
                  fullWidth
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={companyList}
                  getOptionLabel={(option) => option.company_name}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions
                  size="small"
                  value={formData.userCompany}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company"
                      placeholder="Company"
                    />
                  )}
                  onChange={(e, value) =>
                    setFormData({ ...formData, userCompany: value })
                  }
                />
              </Col>

              {editData.valid ? (
                <Col md={6} className="d-flex mb-3">
                  <div className="text-center">
                    <FormControlLabel
                      control={<Switch size="small" />}
                      label="Disable User"
                      onChange={handleChangetoggle}
                      checked={formData.disable}
                      name="disable"
                    />
                  </div>
                </Col>
              ) : (
                ""
              )}

              <Col md={6} className="d-flex">
                <div className="text-center">
                  <FormControlLabel
                    control={<Switch size="small" />}
                    label="Enable Login"
                    onChange={handleChangetoggle}
                    checked={formData.enable_login}
                    name="enable_login"
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="w-100 mt-1 text-right">
                  {!editData.type ? (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={submitDoc}
                      className="me-3"
                    >
                      {editData.valid ? "Update" : "Save"}
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button variant="danger" size="sm" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
}
