import MainLayout from "../../components/MainLayout";
import CommonDataGrid from "../../components/CommonDataGrid";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Checkbox, FormControlLabel, Switch, TextField } from "@mui/material";
import { CommonController } from "../../_redux/common.controller";
import { FaPenToSquare } from "react-icons/fa6";
import { FaEye, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

export default function FileCategory() {
  const [checked, setChecked] = useState(true);

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    file_category_id: 0,
    file_category: "",
    company_id: [],
    disable: false,
  });
  const handleClose = () => {
    setShow(false);
    setview(false);
    setFormData({
      file_category_id: 0,
      file_category: "",
      company_id: [],
    });
  };
  const handleShow = () => setShow(true);
  const [valid, setvalid] = useState(false);
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add File Category",
  });
  const [view, setview] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);
  const [companyList, setcompanyList] = useState([]);
  const [filter, setfilter] = useState(false);

  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [lengthyRemarks, setLengthyRemarks] = useState("");

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sr No.",
        enableColumnFilter: false,
        minSize: 29,
        size: 56,
      },
      {
        accessorKey: "file_category",
        header: "File Category",
        size: 550,
        enableColumnFilter: false,
        minSize: 65,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },
      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        minSize: 31,
        size: 85,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FaPenToSquare
              size={10}
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.file_category_id,
                  type: false,
                  label: "Edit File Category",
                });
              }}
              style={{ fontSize: "15px", cursor: "pointer", color: "blue" }}
            />
            <FaEye
              size={10}
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.file_category_id,
                  type: true,
                  label: "Preview File Category",
                });
              }}
              style={{
                fontSize: "15px",
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />{" "}
            <FaTrash
              size={10}
              onClick={() => handleDeleteRow(row.original.file_category_id)}
              style={{ fontSize: "15px", cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleChangetoggle = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const CompanyList = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "auth/list_company_user",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setcompanyList(result.data);
            setFormData({ ...formData, company_id: result.data });
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };

  const submitDoc = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_file_category",
        formData,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setview(true);
            setupdateGrid(updateGrid + 1);

            setFormData({
              file_category: "",
              file_category_id: 0,
              disable: false,
              company_id: [],
            });
            handleClose();
            toast.success("New File Category Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/delete_file_category",
        {
          file_category_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Succesfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };

  const handleEdit = (data) => {
    console.log(data, "daata");
    handleShow();
    const val = { ...data };
    console.log(data, "daata");
    val.valid = true;
    seteditData(val);

    try {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/preview_file_category",
        {
          file_category_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formData };
          // setvalidSubmit(true);

          for (let key in formData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setFormData(tempData);
        }
      });
    } catch (err) {
      console(err);
    }
  };

  const handleCountrySelect = (val) => {
    const arr = [...formData.company_id];
    return arr.filter((value) => value.company_id === val.company_id).length > 0
      ? true
      : false;
  };

  const handleBodyFilter = (data) => {
    setFormData(data);
    setfilter(false);
  };

  useEffect(() => {
    CompanyList();
  }, []);

  return (
    <MainLayout
      pageName="File Category"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Add File Category" });
        CompanyList();
      }}
      isAddBtnVisible={true}
    >
      <CommonDataGrid
        url={"quickAdminDocMangement/browse_file_category"}
        columns={gridColumn}
        body={""}
        // bodyData={() => setvalid(false)}
        jsonUpd={updateGrid}
        gridHeight={370}
        bodyData={handleBodyFilter}
      />

      <ToastContainer />

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>{editData.label}</h4>
          <Form className="mt-3">
            <Row>
              <Col md={6}>
                <TextField
                  size="small"
                  label="File Category*"
                  variant="outlined"
                  className="w-100"
                  name="file_category"
                  value={formData.file_category}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>

              <Col md={4}>
                <FormControlLabel control={<Switch />} label="Disable" />
              </Col>

              <Col md={6} className=" mt-3" style={{ marginLeft: "13px" }}>
                <Row>
                  <Table bordered size="sm" color="dark">
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyList.length > 0
                        ? companyList.map((val, ind) => {
                            return (
                              <tr key={val.company_id}>
                                <td className="text-left">
                                  {val.company_name}
                                </td>
                                <td className="text-center">
                                  <FormControlLabel
                                    control={<Checkbox />}
                                    onChange={(e) => {
                                      const valid = e.target.checked;
                                      if (valid) {
                                        const arr = [...formData.company_id];
                                        arr.push(val);
                                        setFormData({
                                          ...formData,
                                          company_id: arr,
                                        });
                                      } else {
                                        const arr = [...formData.company_id];
                                        const index = arr.findIndex(
                                          (value) =>
                                            value.company_id === val.company_id
                                        );
                                        arr.splice(index, 1);
                                        setFormData({
                                          ...formData,
                                          company_id: arr,
                                        });
                                      }
                                    }}
                                    checked={handleCountrySelect(val)}
                                    name={val.company_id}
                                    disabled={!editData.type ? false : true}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                </Row>
              </Col>
              <Col md={12} className="mb-3">
                <div className="w-100 mt-1 text-right">
                  {!editData.type ? (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={submitDoc}
                      className="me-3"
                    >
                      {editData.valid ? "Update" : "Save"}
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button variant="danger" size="sm" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
}
