import React from "react";
import MainLayout from "../../../components/MainLayout";
import { Carousel, Col, Container, Row, Table } from "react-bootstrap";
import { FaFileAlt } from "react-icons/fa";
import { TbCategoryFilled } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { nodeUrl } from "../../../config";

function GridPreview() {
  const location = useLocation();
  return (
    <MainLayout pageName="Document Preview">
      <Container fluid className="grid_preview_wrapper">
        <div className="white_box">
          <Row>
            {location.state.length > 0 &&
              location.state.map((item) => (
                <>
                  <Col md={5}>
                    <Row>
                      {item.viewAttachement &&
                        item.viewAttachement.map((attach) => {
                          return (
                            <Col
                              md={item.viewAttachement.length > 1 ? 6 : 12}
                              className="h-100"
                            >
                              <iframe
                                src={`${nodeUrl}${attach.path}`}
                                className="w-100 h-100"
                                alt=""
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                  <Col md={7}>
                    <div>
                      <h5>
                        {item.ID}/{item.document_type}
                      </h5>
                    </div>
                    <div className="bg-light rounded-2 border p-md-3 p-3 mb-3">
                      <h5>
                        <FaFileAlt /> Files
                      </h5>
                      <hr />
                      <ul className="details_list">
                        {item.viewFile.length > 0 &&
                          item.viewFile.map((file) => (
                            <li>
                              <b>{file.file_name}</b>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="bg-light rounded-2 border p-md-3 p-3">
                      <h5>
                        <TbCategoryFilled /> Document Details
                      </h5>
                      <div className="bg-white p-3 rounded-2 border">
                        <Table bordered className="m-0">
                          <tbody>
                            <tr>
                              {item.viewParameter.length > 0 &&
                                item.viewParameter.map((parameter) => (
                                  <>
                                    <td>
                                      <strong> {parameter.field_name}</strong>:
                                      {parameter.field_value}
                                    </td>
                                  </>
                                ))}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </>
              ))}
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default GridPreview;
