import React, { useState } from "react";
import LoginLayout from "../authentication/LoginLayout";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaCheck, FaPhone, FaWhatsapp } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { userService } from "../../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authShareUser, authUser } from "../../components/common";

function ConfirmOTP() {
	const { contact_person, mobile } = useParams();
	const navigate = useNavigate();

	const [authParams, setAuthParams] = useState({
		user_name: mobile,
		otp: "",
	});
	const [showOTP, setShowOTP] = useState(false);

	const handleChange = (event) => {
		setAuthParams({ ...authParams, [event.target.name]: event.target.value });
	};

	const handleGetOtp = async (e) => {
		e.preventDefault();

		try {
			const response = await userService.login("shareauth/sharelogin", {
				user_name: authParams.user_name,
			});
			if (response.data.valid) {
				toast.success("Check Your Mobile for OTP !", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setShowOTP(true);
			} else {
				toast.error("Please Enter Valid Number !", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} catch (err) {
			console.log(err);
		}
		//    setLoading(false);
	};

	const submitSign = async (e) => {
		e.preventDefault();
		// setLoading(true);
		try {
			const response = await userService.login(
				"shareauth/sharecheckotp",
				authParams
			);
			if (response.data.valid) {
				authShareUser(response.data.token);
				// window.location.reload();
				navigate("/docpreviewShare");
			} else {
				toast.error("Wrong OTP !", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} catch (err) {
			console.log(err);
		}
		// setLoading(false);
	};

	return (
		<LoginLayout>
			<Row>
				<ToastContainer />

				<Col md={12}>
					{showOTP ? (
						<>
							<h5 className="mt-4 text-center">Confirm OTP</h5>
							<Form>
								<Form.Control
									type="number"
									name="otp"
									value={authParams.otp}
									placeholder="Enter OTP"
									onChange={handleChange}
									className="mb-3"
								/>
								<Button type="submit" variant="success" onClick={submitSign}>
									Confirm
								</Button>
							</Form>
						</>
					) : (
						<>							
							<div className="bg-light pb-1 rounded-3 border p-3 mt-4 mb-4">
								<h5>
									Name: <strong>{contact_person}</strong>
								</h5>
								<h5>
									<Form.Group className="mb-3 position-relative">
										<FaWhatsapp
											color="#075e54"
											size={25}
											className="position-absolute"
											style={{
												top: "50%",
												transform: "translateY(-50%)",
												left: "10px",
											}}
										/>
										<Form.Control
											type="tel"
											name="user_name"
											value={mobile}
											disabled
											className="ps-5"
										/>
									</Form.Group>
									{/* Phone: <strong>{mobile}</strong> */}
								</h5>
							</div>
              <Button className="w-100" variant="success" onClick={handleGetOtp}>
								Genrate OTP
							</Button>
						</>
					)}
				</Col>
			</Row>
		</LoginLayout>
	);
}

export default ConfirmOTP;
