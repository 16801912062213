import { Button, Col, Form, Row } from "react-bootstrap";
import LoginLayout from "./LoginLayout";
import { Link } from "react-router-dom";

export default function SignUp() {
  return (
    <LoginLayout>
      <Row>
        <Col className="text-center">
          <h4>Sign Up</h4>
          <p>Enter your email and password to register</p>
        </Col>
        <Col md={12} className="mt-2">
          <Form>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control type="password" placeholder="Set password" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
        <Col md={12} className="text-center mt-3">
          Already have account? <Link to="/sign-in">Sign In</Link>
        </Col>
      </Row>
    </LoginLayout>
  );
}
