import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { FaBars, FaFileExcel, FaPlus } from "react-icons/fa";
import classes from "./MainLayout.module.css";
import { useEffect, useState } from "react";
import Header from "./Header";
import SidebarMenu from "./SidebarMenu";
import { useMediaQuery } from "@mui/material";
import { FaRotateRight } from "react-icons/fa6";
import { Link, Outlet } from "react-router-dom";

export default function MainLayout({
  children,
  pageName,
  isOpen,
  isAddBtnVisible,
  isDashboardTabsVisible,
}) {
  const windowSize = useMediaQuery("(min-width:991px");
  const [isActiveSidemenu, setIsActiveSidemenu] = useState(windowSize);
  const [showOverlay, setShowOverlay] = useState(windowSize);
  // const [activeTab, setActiveTab] = useState("tasks");
  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };
  // useEffect (() => {
  //   const path= window.location.pathname;
  //   if (path.includes("/dashboard/uploads")){
  //     setActiveTab("uploads");
  //   }else if (path.includes("/dashboard/credentials")){
  //     setActiveTab("credentials");
  //   } else {
  //     setActiveTab("tasks")
  //   }
  // }, []);
  const toggleSidebar = () => {
    setIsActiveSidemenu((prev) => !prev);
    setShowOverlay((prev) => !prev);
  };

  return (
    <>
      <Header />
      {showOverlay ? (
        <div className={classes.mobile_overlay} onClick={toggleSidebar}></div>
      ) : (
        ""
      )}
      <main className={classes.wrapper}>
        <div
          className={`${classes.sidebar_menu} ${
            isActiveSidemenu ? classes.showMenu : ""
          }`}
        >
          <SidebarMenu />
        </div>
        <div
          className={`${classes.main_wrapper} ${
            !isActiveSidemenu ? classes.fullBox : ""
          }`}
        >
          <Container
            fluid
            className={`${classes.top_menu__wrapper} ${
              !isActiveSidemenu ? classes.showMenu : ""
            }`}
          >
            <Row className="w-100 align-items-center">
              <Col className="d-flex align-items-center" xs={8}>
                <span className={classes.menu_icon} onClick={toggleSidebar}>
                  <FaBars size={13} />
                </span>
                <div className={classes.breadcrumb__wrapper}>
                  <Breadcrumb>
                    <Breadcrumb.Item className="active_page_crumb" active>
                      <h5 className={`m-0 fw-bold ms-3 ${classes.pageTitle}`}>
                        {pageName}
                      </h5>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              {isAddBtnVisible && (
                <Col xs={4}>
                  <div className="d-flex justify-content-end gap-2">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => isOpen(true)}
                    >
                      <FaPlus /> Add
                    </Button>
                  </div>
                </Col>
              )}
              {/* {isDashboardTabsVisible && (
                <Col xs={4}>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <Link
                      to="/dashboard/tasks"
                      className={`tabbtn  ${
                        activeTab === "tasks" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tasks")}
                    >
                      Task
                    </Link>
                    <Link
                      to="/dashboard/uploads"
                      className={`tabbtn  ${
                        activeTab === "uploads" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("uploads")}
                    >
                      Uploads
                    </Link>
                    <Link
                      to="/dashboard/credentials"
                      className={`tabbtn  ${
                        activeTab === "credentials" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("credentials")}
                    >
                      Credentials
                    </Link>
                  </div>
                </Col>
              )} */}
            
            </Row>
          </Container>
          <div className={classes.inner_wrapper}>{children}</div>
        </div>
      </main>
    </>
  );
}
