import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MainLayout from "../components/MainLayout";
import { useRef } from "react";
import { FaPen, FaPenAlt } from "react-icons/fa";
import { TextField } from "@mui/material";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CommonController } from "../_redux/common.controller";
import { userService } from "../services";

export default function Profile() {
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);

  const [formData, setFormData] = useState({
    profile_pic: "",
    user_id: 0,
  });

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const submitDoc = async (e) => {
    // const data = e.target.files[0];
    const data = new FormData();
    data.append(`file_path`, e.target.files[0]);
    try {
      await userService
        .uploadImage("quickAdminUser/update_user_pic", data)
        .then((result) => {
          if (result.valid) {
            //  handleData(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };

  return (
    <MainLayout pageName="Profile" isAddBtnVisible={false}>
      <Container fluid>
        <div className="white_box p-4">
          <Row>
            <ToastContainer />
            <Col md={3}>
              <div className="profile_photo_box">
                <input
                  type="file"
                  accept="image/*"
                  onChange={submitDoc}
                  ref={imageUploader}
                  style={{
                    display: "none",
                  }}
                  value={formData.profile_pic}
                  name="profile_pic"
                />
                <div
                  className="profile_image_box"
                  onClick={() => imageUploader.current.click()}
                >
                  <img ref={uploadedImage} alt="" />
                  <FaPen className="icon" />
                </div>
              </div>
            </Col>
            <Col>
              <Form>
                <Row>
                  <Col md={12} className="mb-2">
                    <h6>Edit Profile</h6>
                  </Col>
                  <Col md={6} className="mb-3">
                    {" "}
                    <TextField
                      label="Name"
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    {" "}
                    <TextField
                      label="Email"
                      size="small"
                      variant="outlined"
                      fullWidth
                      type="email"
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    {" "}
                    <TextField
                      label="Mobile"
                      size="small"
                      variant="outlined"
                      fullWidth
                      type="number"
                    />
                  </Col>
                  <Col md={12}>
                    <Button variant="success" size="sm" onClick={submitDoc}>
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}
