//new data of whole grid
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { CommonController } from "../../../_redux/common.controller";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Link } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import {
  AutoCompleteDropdown,
  AutoCompleteDropdownId,
} from "../../../components/autoCompleteDropdown";
import moment from "moment";
import { DateRange } from "../../../components/common";
import UploadImage from "./uploadImage";
import MainLayout from "../../../components/MainLayout";
import CommonDataGrid from "../../../components/CommonDataGrid";
import { FaPenToSquare } from "react-icons/fa6";
import { FaExternalLinkAlt, FaEye, FaFilePdf, FaTrash } from "react-icons/fa";
import { nodeUrl } from "../../../config";
export default function Uploads() {
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const date = new Date();
  const [fileList, setFileList] = useState([]);
  const [documentAttachement, setdocumentAttachement] = useState([]);
  const [attachement, setattachement] = useState([]);
  const [show, setShow] = useState(false);
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add File Category",
  });
  const handleShow = () => setShow(true);
  const [docType, setdocType] = useState({
    date_of_document: null,
    date_of_receiving: null,
    financial_year: null,
  });
  const [view, setview] = useState(false);
  const [docTypeParameter, setdocTypeParameter] = useState([]);
  // const handleBodyFilter = (data) => {
  //   setFormData(data);
  //   setfilter(false);
  // };
  const [updateGrid, setupdateGrid] = useState(0);
  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        // "quickAdminCredential/delete_credential",
        {
          document_category_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Succesfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };
  const [formdata, setformdata] = useState({
    document_id: 0,
    ID: date.valueOf(),
    document_category_id: "",
    document_category: "",
    document_type: "",
    document_type_id: "",
    date_of_receiving: new Date(),
    date_of_document: new Date(),
    financial_year: "",
    fileType: [],
    search: "",
    field_name: "",
    field_value: "",
    // document_attachment: [],
  });
  const handleClose = () => {
    setShow(false);
    setdocTypeParameter([]);
    setattachement([]);
    setformdata([]);
    setupdateGrid(Math.floor(Math.random() * 10));
  };
  const previewAttachement = async (id) => {
    try {
      setdocumentAttachement([]);
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/preview_attachement",
        { document_id: id },
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setdocumentAttachement(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const getDocumentTypeParameter = async (id) => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/list_doc_type_parameter",
        { document_type_id: id },
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setdocTypeParameter(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const getFileList = async (val) => {
    getDocumentTypeParameter(val.document_type_id);
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/list_doc_type_file",
        { document_type_id: val.document_type_id },
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setformdata({
              ...formdata,
              document_type: val.document_type,
              document_type_id: val.document_type_id,
              fileType: result.data,
            });
            // setformdata({...formdata,fileType:result.data});
          } else {
            setformdata({
              ...formdata,
              document_type: val.document_type,
              document_type_id: val.document_type_id,
            });
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const submitDoc = async () => {
    try {
      const obj = { ...formdata };
      obj.dcoParameter = docTypeParameter;
      obj.attachement = attachement;
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_upload",
        obj,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            // showSuccessToast("Success");
            // setview(true);
            // setupdateGrid(updateGrid + 1);
            // setFormData({ ...formData, file_category_id: result.data });
            // setFormData({
            //   file_category: "",
            //   file_category_id: 0,
            // });
            handleClose();

            // window.location.reload();
          } else {
            alert(result.message.originalError.info.message);
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "S.N.",
        minSize: 24,
        size: 40,
        enableColumnFilter: false,
      },
      {
        accessorKey: "document_category_id",
        header: "Category ID",
        enableColumnFilter: false,
        minSize: 58,
        size: 85,
      },
      {
        accessorKey: "document_category",
        header: "Category",
        enableColumnFilter: false,
        minSize: 44,
        size: 205,
      },
      {
        accessorKey: "document_type",
        header: "Type",
        enableColumnFilter: false,
        minSize: 24,
        size: 180,
      },
      //want to add the popup to preview the files
      {
        accessorKey: "document_id",
        header: "Attach.",
        width: 400,
        enableColumnFilter: false,
        minSize: 35,
        size: 56,
        Cell: ({ renderedCellValue, row }) => (
          <div className="text-center w-100">
            <FaExternalLinkAlt
              style={{ cursor: "pointer" }}
              color="#ce1e1e"
              size={10}
              onClick={() => {
                previewAttachement(renderedCellValue);
                setShowAttachmentModal(true);
              }}
            />
          </div>
        ),
      },

      {
        accessorKey: "upload_date",
        header: "Upload Date",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) =>
          moment(renderedCellValue).format("DD/MM/YYYY"),
        minSize: 61,
        size: 106,
      },
      {
        accessorKey: "upload_by",
        header: "Upload By",
        enableColumnFilter: false,
        minSize: 50,
        size: 114,
      },
      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        minSize: 31,
        size: 59,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FaPenToSquare
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.document_id,
                  type: false,
                  label: "Edit Documents ",
                });
              }}
              style={{ cursor: "pointer", color: "blue" }}
            />
            <FaEye
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.document_id,
                  type: true,
                  label: "Preview Uploads",
                });
              }}
              style={{
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />{" "}
            <FaTrash
              onClick={() => handleDeleteRow(row.original.document_category_id)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    []
  );

  // const submitShare = async () => {
  //   console.log(sharingdata);
  //   try {
  //     await CommonController.commonApiCallFilter(
  //       "",
  //       sharingdata,
  //       "post",
  //       "node"
  //     )
  //       .then((result) => {
  //         if (result.valid) {
  //           toast.success("New Document Category Added !", {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         // showErrorToast(err);
  //       });
  //   } catch (err) {
  //     // showErrorToast(err);
  //   }
  // };

  const getFileListFullData = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/file_list",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setFileList(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const handleEdit = ({ id }) => {
    handleShow();
    try {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/preview_document_upload",
        {
          document_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formdata };
          // setvalidSubmit(true);

          for (let key in formdata) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setformdata(tempData);
          setdocTypeParameter(data.docParameter);
          setattachement(data.attachement);
        }
      });
    } catch (err) {
      console(err);
    }
  };

  useEffect(() => {
    getFileListFullData();
  }, []);
  const bodyUseMemo = useMemo(() => {
    return {
      document_category_id: formdata.document_category_id,
    };
  }, [formdata.document_category_id]);

  // const handleAttachement=useMemo(() => attachement, [attachement])

  return (
    <MainLayout
      pageName="Upload Document"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Uploads" });
      }}
      isAddBtnVisible={true}
    >
      <CommonDataGrid
        url={"quickAdminDocMangement/browse_documents"}
        columns={gridColumn}
        body={""}
        jsonUpd={updateGrid}
        gridHeight={370}
        // bodyData={handleBodyFilter}
      />
      {/* MODAL================== MODAL */}
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>Uploads</h4>
          <Form className="mt-3">
            <Row>
              <Col md={4} className="mb-3">
                <TextField
                  label="Document ID"
                  disabled
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={formdata.ID}
                />
              </Col>
              <Col md={4} className="mb-3">
                <AutoCompleteDropdown
                  url={"quickAdminDocMangement/list_doc_category"}
                  handleDataChange={(val) => {
                    setformdata({
                      ...formdata,
                      document_category_id: val.document_category_id,
                      document_category: val.document_category,
                    });
                  }}
                  labelName={"Document Category*"}
                  valueInput={formdata.document_category}
                  objLevel={"document_category"}
                  // disable={!editData.type ? false : true}
                />
              </Col>
              <Col md={4} className="mb-3">
                {formdata.document_category_id ? (
                  <AutoCompleteDropdownId
                    url={"quickAdminDocMangement/list_doc_type"}
                    body={bodyUseMemo}
                    handleDataChange={(val) => {
                      setdocType(val);
                      getFileList(val);
                    }}
                    labelName={"Document Type*"}
                    valueInput={formdata.document_type}
                    objLevel={"document_type"}
                    // disable={!editData.type ? false : true}
                  />
                ) : (
                  ""
                )}
              </Col>
              {formdata.document_type_id ? (
                <Col md={12} className="mb-3">
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    // disabled={!editData.type ? false : true}
                    options={fileList}
                    getOptionLabel={(option) => option.file_name}
                    // defaultValue={[top100Films[13]]}
                    value={formdata.fileType}
                    filterSelectedOptions
                    size="small"
                    onChange={(e, val) =>
                      setformdata({ ...formdata, fileType: val })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tag Files"
                        placeholder="Favorites"
                      />
                    )}
                  />
                </Col>
              ) : (
                ""
              )}
              <Col md={12}>
                {formdata.document_type_id ? (
                  <Row>
                    {docType.date_of_document ? (
                      <Col md={4} className="mb-3">
                        <TextField
                          size="small"
                          type="date"
                          label="Date of Document"
                          variant="outlined"
                          fullWidth
                          name="date_of_document"
                          value={moment(formdata.date_of_document).format(
                            "YYYY-MM-DD"
                          )}
                          onChange={(e) =>
                            setformdata({
                              ...formdata,
                              date_of_document: e.target.value,
                            })
                          }
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    {docType.date_of_receiving ? (
                      <Col md={4} className="mb-3">
                        <TextField
                          size="small"
                          type="date"
                          label="Date of receiving"
                          variant="outlined"
                          fullWidth
                          name="date_of_receiving"
                          value={moment(formdata.date_of_receiving).format(
                            "YYYY-MM-DD"
                          )}
                          onChange={(e) =>
                            setformdata({
                              ...formdata,
                              date_of_receiving: e.target.value,
                            })
                          }
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                ) : (
                  ""
                )}
              </Col>
              <Col md={12}>
                <Row>
                  {docTypeParameter.length > 0
                    ? docTypeParameter.map((val, ind) => {
                        return (
                          <>
                            {val.field_type === "alphabetic" ? (
                              <Col md={4} className="mb-3">
                                <TextField
                                  type="text"
                                  size="small"
                                  label={val.field_name}
                                  variant="outlined"
                                  fullWidth
                                  onChange={(e) => {
                                    const arr = [...docTypeParameter];
                                    arr[ind].field_value = e.target.value;
                                    setdocTypeParameter(arr);
                                  }}
                                  name="alphabetic"
                                  value={val.field_value}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {val.field_type === "numeric" ? (
                              <Col md={4} className="mb-3">
                                <TextField
                                  type="number"
                                  size="small"
                                  label={val.field_name}
                                  variant="outlined"
                                  fullWidth
                                  name="alphabetic"
                                  onChange={(e) => {
                                    const arr = [...docTypeParameter];
                                    arr[ind].field_value = e.target.value;
                                    setdocTypeParameter(arr);
                                  }}
                                  value={val.field_value}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {val.field_type === "date" ? (
                              <Col md={4} className="mb-3">
                                <TextField
                                  type="date"
                                  size="small"
                                  label={val.field_name}
                                  variant="outlined"
                                  fullWidth
                                  name="alphabetic"
                                  value={moment(val.field_value).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={(e) => {
                                    const arr = [...docTypeParameter];
                                    arr[ind].field_value = e.target.value;
                                    setdocTypeParameter(arr);
                                  }}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {val.field_type === "daterange" ? (
                              <Col md={4} className="mb-3">
                                <DateRange
                                  date={
                                    val.field_value
                                      ? {
                                          from_date:
                                            val.field_value.split("-")[0],
                                          to_date:
                                            val.field_value.split("-")[1],
                                        }
                                      : null
                                  }
                                  label={val.field_name}
                                  onChange={(e) => {
                                    const arr = [...docTypeParameter];
                                    arr[ind].field_value =
                                      moment(e.startDate).format("MM/DD/YYYY") +
                                      "-" +
                                      moment(e.endDate).format("MM/DD/YYYY");
                                    setdocTypeParameter(arr);
                                  }}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {val.field_type === "list" ? (
                              <Col md={4} className="mb-3">
                                <Autocomplete
                                  id="tags-outlined"
                                  // disabled={!editData.type ? false : true}
                                  options={val.list}
                                  getOptionLabel={(option) => option.value}
                                  value={{ value: val.field_value }}
                                  filterSelectedOptions
                                  size="small"
                                  onChange={(e, val) => {
                                    const arr = [...docTypeParameter];
                                    arr[ind].field_value = val.value;
                                    setdocTypeParameter(arr);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={val.field_name}
                                      placeholder="Favorites"
                                    />
                                  )}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    : ""}
                </Row>
              </Col>
              {/* {/* <Form.Group controlId="attachfile"> */}
              <Col md={12}>
                <UploadImage
                  data={useMemo(() => attachement, [attachement])}
                  handleData={(data) =>
                    setattachement((val) => [...val, ...data])
                  }
                  deleteData={(data) => setattachement(data)}
                />
              </Col>
              {/* </Form.Group> */}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" size="sm" onClick={submitDoc}>
            Save
          </Button>
          <Button variant="danger" size="sm" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* // link option  */}
      <Modal
        size="xl"
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        className="add_table_data"
        centered
      >
        <Modal.Body className="p-1">
          <Row className="modal_gallery_pdf_list">
            <Col md={3}>
              <ul>
                <li>
                  {documentAttachement.length > 0
                    ? documentAttachement.map((val, ind) => (
                        <Link className="bg-danger">
                          <FaFilePdf color="text-danger" size={10} />{" "}
                          <span
                            onClick={() =>
                              window.open(`${nodeUrl}/${val.attach_path}`)
                            }
                          >
                            {val.attach_original_name}
                          </span>
                        </Link>
                      ))
                    : "No Attachement"}
                </li>
              </ul>
            </Col>
            <Col md={9}>
              <div className="modal-gallery-preview-wrapper">
                <Row>
                  {documentAttachement.length > 0
                    ? documentAttachement.map((val, ind) => (
                        <Col
                          md={3}
                          onClick={() =>
                            window.open(`${nodeUrl}/${val.attach_path}`)
                          }
                        >
                          <img
                            src={`${nodeUrl}/${val.attach_path}`}
                            className="w-100 border mb-3"
                            alt=""
                          />
                        </Col>
                      ))
                    : "No Attachement"}
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
}
