import MainLayout from "../../components/MainLayout";
import CommonDataGrid from "../../components/CommonDataGrid";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { CommonController } from "../../_redux/common.controller";
import { AutoCompleteDropdown } from "../../components/autoCompleteDropdown";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SketchPicker } from "react-color";
import { FaPenToSquare } from "react-icons/fa6";
import { FaEye, FaTrash } from "react-icons/fa";

import Table from "react-bootstrap/Table";
import OutsideClickHandler from "react-outside-click-handler";
import { userService } from "../../services";
import { nodeUrl } from "../../config";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

export default function Tasks() {
  const latestDate = new Date();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    task_id: 0,
    task_category_id: "",
    task_category: "",
    task: "",
    // date: moment().format("L"),
    date: new Date(),
    remarks: "",
    assignto_id: "",
    assignto_name: "",
    reoccurance: "",
    start_date: latestDate,
    end_date: latestDate,
    color: "#fff",
    priority: "",
    user_id: "",
    company_id: null,
    disable: false,
    assignto_cc: [],
  });
  const handleClose = () => {
    setShow(false);
    setview(false);
    setFormData({
      task_id: 0,
      task_category_id: "",
      task_category: "",
      task: "",
      date: null,
      assignto_id: "",
      assignto_name: "",
      reoccurance: "",
      start_date: latestDate,
      end_date: latestDate,
      color: "#000000",
      priority: "",
      user_id: "",
      remarks: "",
      hide_review: false,
      hide_complete: false,
      company_id: null,
      assignto_cc: [],
    });
  };

  const handleShow = () => setShow(true);
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add Task",
  });

  const [view, setview] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);

  //  const [color, setColor] = useState("#ddd");
  const [showPicker, setShowPicker] = useState(false);
  const [assigndata, setassigndata] = useState([]);
  const [filter, setfilter] = useState(false);

  const [openApproved, setOpenApproved] = useState(false);
  const [openMarkComplete, setopenMarkComplete] = useState(false);
  const [openPendingTask, setopenPendingTask] = useState(false);

  const handleCloseApproved = () => setOpenApproved(false);

  const handleMarkcomplete = () => setopenMarkComplete(false);

  const handlePendingCom = () => setopenPendingTask(false);

  const reoccuranceOptions = [
    "None",
    "Daily",
    "Weekly",
    "Monthly",
    "Half Yearly",
    "Yearly",
  ];
  const priorityOptions = ["Low", "Medium", "High"];

  const [attachement, setattachement] = useState([]);

  const [reassignTask, setreassignTask] = useState({
    task_id: "",
    assignby_id: "",
    assignto_id: "",
    remarks: "",
  });

  const [markComplete, setmarkComplete] = useState({
    task_id: "",
    mark_complete: false,
    remarks: "",
  });

  const [pendingTask, setpendingTask] = useState({
    task_id: "",
    mark_review: false,
    remarks: "",
  });

  const [startDate, setStartDate] = useState(new Date());

  const [reassignData, setreassignData] = useState([]);

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/delete_task",
        {
          task_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          setupdateGrid(updateGrid - 1);
          toast.success("Successfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };

  const handleColorChange = (newColor) => {
    setFormData({ ...formData, color: newColor.hex });
  };

  const handleCheckBox = (event) => {
    setmarkComplete({
      ...markComplete,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCheckBoxPending = (event) => {
    setpendingTask({
      ...pendingTask,
      [event.target.name]: event.target.checked,
    });
  };

  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [lengthyRemarks, setLengthyRemarks] = useState("");

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "color",
        header: "",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <div
              style={{
                backgroundColor: renderedCellValue,
                fontSize: "0",
                width: "20px",
                height: "100%",
              }}
            ></div>
          );
        },
        width: 20,
        minSize: 20,
        size: 20,
      },
      {
        accessorKey: "id",
        header: "S.N.",
        minSize: 24,
        size: 38,
        enableColumnFilter: false,
      },

      {
        accessorKey: "task",
        header: "Task",
        width: 400,
        enableColumnFilter: false,
        minSize: 24,
        size: 76,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <div className="d-flex align-items-start remark_lengthy">
              <div
                className=""
                style={{
                  color:
                    row.original.priority === "High"
                      ? "green"
                      : row.original.priority === "Medium"
                      ? "brown"
                      : row.original.priority === "Low"
                      ? "violet"
                      : "transparent",
                  width: "50px",
                  height: "15px",
                  marginRight: "7px",
                  marginTop: "2px",
                  borderRadius: "3px",
                  textShadow: "1px 1px 1px rgb(0 0 0 / 20 %)",
                }}
                onClick={() => {
                  setLengthyRemarks(renderedCellValue);
                  setShowRemarksModal(true);
                }}
              >
                <span className="flex-1 fw-bold text-decoration-underline">
                  {row.original.task}
                </span>
              </div>
            </div>
          );
        },
      },
      // {
      //   accessorKey: "task_category",
      //   header: "Task Category",
      //   width: 400,
      //   enableColumnFilter: false,
      //   minSize: 85,
      // },
      {
        accessorKey: "assign_by",
        header: "Assign By",
        width: 400,
        enableColumnFilter: false,
        minSize: 48,
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },

      {
        accessorKey: "assign_to",
        header: "Assign To",
        width: 400,
        enableColumnFilter: false,
        minSize: 47,
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },

      {
        accessorKey: "MarkCompleted",
        header: "Mark Complete By",
        enableColumnFilter: false,
        minSize: 89,
        size: 141,

        Cell: ({ renderedCellValue, row }) => (
          <div className="text-center w-100 fw-bold text-decoration-underline">
            {row.original.mark_complete ? (
              <>
                <label
                  variant="none"
                  onClick={() => {
                    handleComplete(row.original);
                  }}
                  className={`${
                    row.original.mark_complete
                      ? "text-success "
                      : "text-danger "
                  } table_grid_btn`}
                  disabled
                >
                  {row.original.complete_by}
                </label>
              </>
            ) : (
              <>
                <label
                  variant="none"
                  onClick={() => {
                    handleComplete(row.original);
                  }}
                  className={`${
                    row.original.mark_complete
                      ? "text-success "
                      : "text-danger "
                  } table_grid_btn`}
                >
                  {row.original.complete_by}
                </label>
              </>
            )}
          </div>
        ),
      },

      {
        accessorKey: "Pending",
        header: "Review",
        enableColumnFilter: false,
        minSize: 34,
        size: 135,
        Cell: ({ renderedCellValue, row }) => (
          <div className="text-center w-100 fw-bold text-decoration-underline">
            {row.original.mark_complete ? (
              <>
                <label
                  variant="none"
                  onClick={() => handlePending(row.original)}
                  className={`${
                    row.original.mark_review ? "text-success " : "text-danger "
                  } table_grid_btn`}
                >
                  {row.original.review_by}
                </label>
              </>
            ) : (
              <>
                <label
                  variant="none"
                  onClick={() => handlePending(row.original)}
                  className={`${
                    row.original.mark_review ? "text-success " : "text-danger "
                  } table_grid_btn`}
                  disabled
                >
                  {row.original.review_by}
                </label>
              </>
            )}
          </div>
        ),
      },

      {
        accessorKey: "reassign",
        header: "Reassign",
        enableColumnFilter: false,
        minSize: 43,
        size: 102,
        Cell: ({ renderedCellValue, row }) => (
          <div className="text-center w-100 fw-bold text-decoration-underline">
            {row.original.mark_complete ? (
              <>
                <label
                  variant="none"
                  size="sm"
                  className="table_grid_btn text-primary"
                  onClick={() => handleAssign(row.original)}
                  disabled
                >
                  {row.original.reassign}
                </label>
              </>
            ) : (
              <>
                <label
                  variant="none"
                  size="sm"
                  className="table_grid_btn text-primary fw-bold"
                  onClick={() => handleAssign(row.original)}
                >
                  {row.original.reassign}
                </label>
              </>
            )}
          </div>
        ),
      },

      {
        accessorKey: "remarks",
        header: "Remarks",
        width: 400,
        enableColumnFilter: false,
        minSize: 42,
        size: 78,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },

      {
        accessorKey: "mark_review",
        header: "Mark Com. Remarks",
        minSize: 97,
        size: 129,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },

      {
        accessorKey: "review_remarks",
        header: "Review Remarks",
        width: 400,
        enableColumnFilter: false,
        minSize: 80,
        size: 111,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },

      {
        accessorKey: "reassign_remarks",
        header: "Reassign Remarks",
        width: 400,
        enableColumnFilter: false,
        minSize: 89,
        size: 107,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },

      {
        accessorKey: "attachment",
        header: "Attachments",
        width: 400,
        enableColumnFilter: false,
        minSize: 62,
        size: 84,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <Button
              size="sm"
              className="m-1 column_btn"
              onClick={(e) => handleButtonClick(row.original.attachment)}
            >
              <FaEye />
            </Button>{" "}
          </div>
        ),
      },
      {
        accessorKey: "start_date",
        header: "Start Date",
        width: 200,
        enableColumnFilter: false,
        minSize: 51,
        size: 69,
        Cell: ({ renderedCellValue, row }) => {
          return moment(renderedCellValue).format("DD/MM/yyyy");
        },
      },
      // {
      //   accessorKey: "end_date",
      //   header: "End Date",
      //   width: 200,
      //   enableColumnFilter: false,
      //   minSize: 44,
      //   size: 68,
      //   Cell: ({ renderedCellValue, row }) => {
      //     return moment(renderedCellValue).format("DD/MM/yyyy");
      //   },
      // },
      {
        accessorKey: "date",
        header: "Due Date",
        width: 200,
        enableColumnFilter: false,
        minSize: 45,
        size: 72,
        Cell: ({ renderedCellValue, row }) => {
          return moment(renderedCellValue).format("DD/MM/yyyy");
        },
      },
      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        minSize: 31,
        size: 63,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FaPenToSquare
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.task_id,
                  type: false,
                  label: "Edit Task",
                });
              }}
              style={{ cursor: "pointer", color: "blue" }}
            />
            <FaEye
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.task_id,
                  type: true,
                  label: "Preview Task",
                });
              }}
              style={{
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />{" "}
            <FaTrash
              onClick={() => handleDeleteRow(row.original.task_id)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeTaskReassign = (event) => {
    setreassignTask({
      ...reassignTask,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeMarkComplete = (event) => {
    setmarkComplete({
      ...markComplete,
      [event.target.name]: event.target.value,
    });
  };

  const handlePendingComplete = (event) => {
    setpendingTask({
      ...pendingTask,
      [event.target.name]: event.target.value,
    });
  };

  const handleAssign = (e) => {
    setreassignTask({
      ...reassignTask,
      assignby_id: e.add_user_id,
      task_id: e.task_id,
      assignto_id: e.assignto_id,
      task_head: e.task,
      // remark: e.target.remark,
    });

    try {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/browse_task_reassign",
        {
          task_id: e.task_id,
        },
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setreassignData(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }

    setOpenApproved(true);
  };

  const handleComplete = (e) => {
    setmarkComplete({
      ...markComplete,

      task_id: e.task_id,
      task_head: e.task,
    });
    setopenMarkComplete(true);
  };

  const handlePending = (e) => {
    setpendingTask({
      ...pendingTask,

      task_id: e.task_id,
      task_head: e.task,
    });
    setopenPendingTask(true);
  };

  const assignList = (id) => {
    CommonController.commonApiCallFilter(
      "quickAdminDocMangement/list_task_assign",
      {
        user_id: id,
      },
      "post",
      "node"
    ).then((data) => {
      if (data.valid) {
        setassigndata(data.data);
      }
    });
  };

  const submitDoc = async () => {
    try {
      setFormData({ ...formData, attachment: attachement });

      //   formData.attachments = attachement;
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_task",
        formData,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setview(true);
            setupdateGrid(updateGrid + 1);
            setFormData({
              task_id: 0,
              task_category_id: "",
              task_category: "",
              task: "",
              date: latestDate,
              remarks: "",
              assignto_id: "",
              assignto_name: "",
              reoccurance: "",
              start_date: latestDate,
              end_date: latestDate,
              color: "",
              priority: "",
              user_id: "",
              company_id: null,
              assignto_cc: [],
            });

            handleClose();
            toast.success("New Task Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const submitMarkCompleteTask = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_task_complete",
        markComplete,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            handleMarkcomplete();
            setupdateGrid(updateGrid + 1);
            toast.success("Task Marking Done !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const submitReassignTask = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_task_reassign",
        reassignTask,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            handleCloseApproved();
            setupdateGrid(updateGrid + 1);
            toast.success("Task Reassigned !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const submitpendingTask = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_task_review",
        pendingTask,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            handlePendingCom();
            setupdateGrid(updateGrid + 1);
            toast.success("Done !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleEdit = (data) => {
    handleShow();
    const val = { ...data };
    val.valid = true;
    seteditData(val);

    try {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/preview_task",
        {
          task_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formData };
          // setvalidSubmit(true);

          for (let key in formData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setFormData(tempData);
        }
      });
    } catch (err) {
      console(err);
    }
  };

  const handleBodyFilter = (data) => {
    setFormData(data);
    setfilter(false);
  };

  const handleImageUpload = async (files) => {
    // if (fileUploadShouldFail) {
    //   const corruptedFiles = files.map(file => ({
    //     ...file,
    //     lastModified: 'foo'
    //   }));
    //   setCurrentFiles(prevFiles => [...prevFiles, ...corruptedFiles]);
    // } else {
    const uplData = new FormData();

    uplData.append(`file_path`, files.target.files[0]);
    try {
      await userService
        .uploadImage("quickAdminDocMangement/upload_file_task", uplData)
        .then((result) => {
          if (result.data.valid) {
            setFormData({ ...formData, attachment: result.data.data.path });
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
    // }
  };

  const handleButtonClick = (task_id) => {
    const url = `${nodeUrl}${task_id}`;
    const windowOptions = "width=800,height=600,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };

  useEffect((type) => {
    assignList();
  }, []);

  const handleDateChange = (event) => {
    console.log(event.target.value, "sameer");
    setFormData({ ...formData, date: event.target.value });
  };

  const [color, setColor] = useColor("#121212");

  const handleColor = (e) => {
    setColor(e);
    setFormData({
      ...formData,
      color: color.hex,
    });
  };

  return (
    <MainLayout
      pageName="Task"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Add Task" });
      }}
      isAddBtnVisible={true}
    >
      <Modal show={showRemarksModal} onHide={() => setShowRemarksModal(false)}>
        <Modal.Header closeButton>
          <h6 className="fw-bold m-0">Remark</h6>
        </Modal.Header>
        <Modal.Body>{lengthyRemarks}</Modal.Body>
      </Modal>
      {/* <FormControlLabel
        control={
          <Checkbox
            onClick={(event) => {
              setFormData({
                ...formData,
                hide_complete: event.target.checked,
              });
              // setJsonUpdate(true);
            }}
            color="primary"
            checked={formData.hide_complete === true}
          />
        }
        label="All"
      /> */}
      <div className="task_grid">
        <CommonDataGrid
          url={"quickAdminDocMangement/browse_task"}
          columns={gridColumn}
          body={""}
          // bodyData={() => setvalid(false)}
          jsonUpd={updateGrid}
          gridHeight={370}
          bodyData={handleBodyFilter}
          showPriorityBar={true}
        />
      </div>

      <ToastContainer />

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>{editData.label}</h4>
          <Form className="mt-3">
            <Row>
              <Col md={6} className="mb-3">
                <AutoCompleteDropdown
                  url={"quickAdminDocMangement/list_task_category"}
                  handleDataChange={(val) =>
                    setFormData({
                      ...formData,
                      task_category_id: val.task_category_id,
                      task_category: val.task_category,
                    })
                  }
                  labelName={"Task Category"}
                  valueInput={formData.task_category}
                  objLevel={"task_category"}
                  disabled={view}
                  // multiple
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Task"
                  variant="outlined"
                  className="w-100"
                  name="task"
                  value={formData.task}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={12}>
                <Row className="align-items-end">
                  <Col md={6} className="mb-3">
                    {/* <Autocomplete
                      id="assignto_name"
                      options={assigndata}
                      getOptionLabel={(option) => option.assignto_name}
                      filterSelectedOptions
                      value={formData.assignto_name}
                      onChange={(e, value) => {
                        setFormData({
                          ...formData,
                          assignto_id: value.assignto_id,
                          assignto_name: value.assignto_name,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assign To"
                          />
                          placeholder="Assign To"
                      )}
                      disabled={view}
                      size="small"
                    /> */}
                    <AutoCompleteDropdown
                      url={"quickAdminDocMangement/list_task_assign"}
                      handleDataChange={(val) =>
                        setFormData({
                          ...formData,
                          assignto_id: val.assignto_id,
                          assignto_name: val.assignto_name,
                        })
                      }
                      labelName={"Assign To"}
                      valueInput={formData.assignto_name}
                      objLevel={"assignto_name"}
                      disabled={view}
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <label> Due Date</label>
                    <DatePicker
                      value={moment(formData.date).format("DD/MM/YYYY")}
                      className="inputcommon"
                      // fullWidth
                      onChange={(date) => {
                        // console.log(date);
                        // setStartDate(date);
                        setFormData({
                          ...formData,
                          date: date,
                        });
                      }}
                      disabled={view}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6} className="mb-3">
                        {/* <TextField
                          label="Priority"
                          fullWidth
                          select
                          variant="outlined"
                          size="small"
                          name="priority"
                          onChange={handleChange}
                          value={formData.priority}
                          disabled={view}
                        >
                          <MenuItem value="Low">Low </MenuItem>
                          <MenuItem value="Medim">Medim </MenuItem>
                          <MenuItem value="High">High</MenuItem>
                        </TextField> */}
                        <Autocomplete
                          options={priorityOptions}
                          value={formData.priority}
                          onChange={(event, newValue) => {
                            handleChange({
                              target: { name: "priority", value: newValue },
                            });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Priority"
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="priority"
                              disabled={view}
                            />
                          )}
                        />
                      </Col>

                      <Col md={6} className="mb-3">
                        {/* <TextField
                          label="Reoccurrence "
                          fullWidth
                          select
                          variant="outlined"
                          size="small"
                          name="reoccurance"
                          onChange={handleChange}
                          value={formData.reoccurance}
                          disabled={view}
                        >
                          <MenuItem value="None">None </MenuItem>
                          <MenuItem value="Daily">Daily </MenuItem>
                          <MenuItem value="Weekly">Weekly </MenuItem>
                          <MenuItem value="Monthly">Monthly</MenuItem>
                          <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                          <MenuItem value="Yearly">Yearly</MenuItem>
                        </TextField> */}

                        <Autocomplete
                          options={reoccuranceOptions}
                          value={formData.reoccurance}
                          onChange={(event, newValue) => {
                            handleChange({
                              target: { name: "reoccurance", value: newValue },
                            });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Reoccurrence"
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="reoccurance"
                              disabled={view}
                            />
                          )}
                        />
                      </Col>
                      <Col md={12}>
                        <Row>
                          {formData.reoccurance === "None" ? (
                            ""
                          ) : formData.reoccurance === "" ? (
                            ""
                          ) : (
                            <>
                              <Col md={6} className="mb-3">
                                <label>Start Date</label>

                                <TextField
                                  type="date"
                                  size="small"
                                  variant="outlined"
                                  className="w-100"
                                  name="start_date"
                                  value={moment(formData.start_date).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={handleChange}
                                  disabled={view}
                                />
                              </Col>
                              <Col md={6} className="mb-3">
                                <label>End Date</label>
                                <TextField
                                  type="date"
                                  size="small"
                                  variant="outlined"
                                  className="w-100"
                                  name="end_date"
                                  value={moment(formData.end_date).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={handleChange}
                                  disabled={view}
                                />
                              </Col>
                            </>
                          )}
                          <Col md={12} className="mb-3">
                            <Autocomplete
                              multiple
                              id="tags-outlined"
                              options={assigndata}
                              getOptionLabel={(option) => option.assignto_name}
                              filterSelectedOptions
                              value={formData.assignto_cc}
                              onChange={(e, value) => {
                                setFormData({
                                  ...formData,
                                  assignto_cc: value,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Assign CC"
                                  placeholder="Assign CC"
                                />
                              )}
                              disabled={view}
                              size="small"
                            />
                          </Col>
                          <Col md={4}>
                            {/* <OutsideClickHandler
                              onOutsideClick={() => setShowPicker(false)}
                            >
                              <div className="picker_wrapper">
                                Color:
                                <Button
                                  variant="none"
                                  className="picker_btn border"
                                  onClick={() => setShowPicker((prev) => !prev)}
                                >
                                  <span
                                    style={{
                                      backgroundColor: `${formData.color}`,
                                    }}
                                  ></span>
                                  {formData.color}
                                </Button>
                                {showPicker && (
                                  <SketchPicker
                                    color={formData.color}
                                    onChangeComplete={handleColorChange}
                                    disabled={view}
                                  />
                                )}
                              </div>
                            </OutsideClickHandler> */}

                            <ColorPicker
                              width={400}
                              height={60}
                              color={color}
                              // onChange={setColor}
                              onChange={handleColor}
                              hideHSV
                              dark
                              hideInput={["rgb", "hsv", "hex"]}
                            />
                          </Col>
                          <Col md={8}>
                            <TextareaAutosize
                              name="remarks"
                              size="small"
                              value={formData.remarks}
                              onChange={handleChange}
                              className="w-100 h-100"
                              placeholder="Remarks"
                              disabled={view}
                            />
                          </Col>
                          <Col md={12} className="mt-3">
                            {/* <input type="file" onChange={handleImageUpload} /> */}

                            {!editData.valid ? (
                              <input type="file" onChange={handleImageUpload} />
                            ) : (
                              ""
                            )}
                            {/* <UploadImage
                              data={attachement}
                              handleData={(data) =>
                                setattachement((val) => [...val, ...data])
                              }
                              deleteData={(data) => setattachement(data)}
                              value={formData.attachement}
                            /> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/* <Col md={6}>
                {editData.valid ? (
                  <Col md={2} className="d-flex">
                    <div className="text-center">
                      <FormControlLabel
                        control={<Switch />}
                        label="Disable User"
                        onChange={handleChangetoggle}
                        checked={formData.disable}
                        name="disable"
                        disabled={view}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Col> */}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 mt-1 text-right">
            {!editData.type ? (
              <Button
                variant="success"
                size="sm"
                onClick={submitDoc}
                className="me-3"
              >
                {editData.valid ? "Update" : "Save"}
              </Button>
            ) : (
              ""
            )}
            <Button variant="danger" size="sm" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* REASSIGN TASK POPUP START  */}
      <>
        <Modal
          size="lg"
          show={openApproved}
          onHide={handleCloseApproved}
          className="sopos__modal add_table_data"
          dialogClassName="w-100"
          centered
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0 lh-1">ReAssign Task</h4>
              <h6 className="m-0 lh-1 fst-italic">
                Select User to Reassign the Task
              </h6>
            </div>
            <Badge bg="primary">Task Name : {reassignTask.task_head}</Badge>
            <Form>
              <Row>
                <Col xs={12} className="mt-3 mb-3">
                  <AutoCompleteDropdown
                    url={"quickAdminDocMangement/list_user_task_reassign"}
                    handleDataChange={(val) =>
                      setreassignTask({
                        ...reassignTask,
                        assignto_id: val.user_id,
                        assignto_name: val.full_name,
                      })
                    }
                    labelName={"Reassign To"}
                    valueInput={reassignTask.assignto_name}
                    objLevel={"full_name"}
                    className="mb-3"
                    // disabled={view}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <TextareaAutosize
                    name="remarks"
                    size="small"
                    value={reassignTask.remarks}
                    onChange={handleChangeTaskReassign}
                    className="w-100"
                    style={{ height: "120px" }}
                    placeholder="Remarks"
                    // disabled={view}
                  />
                </Col>
                <Col xs={12} className="d-flex gap-2 align-items-center">
                  <Button
                    variant="success"
                    size="sm"
                    className="global__buttons"
                    onClick={submitReassignTask}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handleCloseApproved}
                    size="sm"
                    className="global__buttons"
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </Col>
                <br />
                <Col md={12} className="mt-3">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Assign By</th>
                        <th>Assign To</th>
                        <th>Remarks</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    {reassignData.length > 0 &&
                      reassignData.map((item) => (
                        <tbody>
                          <tr>
                            <td>{item.assignby}</td>
                            <td>{item.assignto}</td>
                            <td>{item.remarks}</td>
                            {/* <td>{item.datetime}</td> */}
                            <td>
                              {moment(item.datetime).format("DD/MM/yyyy")}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </Table>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </>
      {/* REASSIGN TASK POPUP START  */}

      {/* MARK COMPLETE TASK POPUP  START*/}
      <>
        <Modal
          show={openMarkComplete}
          onHide={handleMarkcomplete}
          className="sopos__modal add_table_data"
          dialogClassName="w-100"
          centered
          size="md"
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0 lh-1">Mark Complete Task</h4>
            </div>
            <Badge bg="primary">Task Name : {reassignTask.task_head}</Badge>
            <Form>
              <Row>
                <Col xs={12} className="mt-3 mb-3">
                  <FormControlLabel
                    control={<Checkbox color="success" />}
                    // disabled={!editData.type ? false : true}
                    label="Mark Complete"
                    onChange={handleCheckBox}
                    checked={markComplete.mark_complete}
                    name="mark_complete"
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <TextareaAutosize
                    name="remarks"
                    size="small"
                    value={markComplete.remarks}
                    onChange={handleChangeMarkComplete}
                    className="w-100"
                    style={{
                      height: "120px",
                    }}
                    placeholder="Remarks"
                    // disabled={view}
                  />
                </Col>
                <Col xs={12} className="d-flex align-items-center gap-2">
                  <Button
                    variant="success"
                    className="global__buttons"
                    onClick={submitMarkCompleteTask}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handleMarkcomplete}
                    className="global__buttons"
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </>
      {/* MARK COMPLETE TASK POPUP  */}

      <>
        <Modal
          show={openPendingTask}
          onHide={handlePendingCom}
          className="sopos__modal add_table_data"
          dialogClassName="w-100"
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0 lh-1">Pending task</h4>
              <h6 className="m-0 lh-1 fst-italic">Mark Review/Close</h6>
            </div>
            <Badge bg="primary">Task Name : {reassignTask.task_head}</Badge>
            <Form>
              <Row>
                <Col xs={12} className="mb-3">
                  <FormControlLabel
                    control={<Checkbox color="success" />}
                    // disabled={!editData.type ? false : true}
                    label="Mark Review"
                    onChange={handleCheckBoxPending}
                    checked={pendingTask.mark_review}
                    name="mark_review"
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <TextareaAutosize
                    name="remarks"
                    size="small"
                    value={pendingTask.remarks}
                    onChange={handlePendingComplete}
                    className="w-100"
                    style={{ height: "120px" }}
                    placeholder="Remarks"
                  />
                </Col>
                <Col xs={12} className="d-flex align-items-center gap-2">
                  <Button
                    variant="success"
                    className="global__buttons"
                    onClick={submitpendingTask}
                    size="sm"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handlePendingCom}
                    className="global__buttons"
                    variant="danger"
                    size="sm"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    </MainLayout>
  );
}
