import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { ImDrawer } from "react-icons/im";
import { NavLink, Outlet } from "react-router-dom";

export default function Drawer() {
  return (
    <>
      <div className="file_location_main_wrapper locationlevel2">
        drwawre file ans 
      </div>
    </>
  );
}
