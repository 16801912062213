import React, { memo, useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Button, Col, Container, Modal } from "react-bootstrap";
// import { TableExportButton, showErrorToast } from "../components/common";
// import { CommonController } from "../_redux/controller/common.controller";
import { FormControl, TextField } from "@mui/material";
import PaginationCustom from "./Pagination";
import { CommonController } from "../_redux/common.controller";
// import { FaFileExcel } from "react-icons/fa";
import { TableExportButton } from "./common";
const user_id = Number(localStorage.getItem("userId"));

const CommonDataGrid = ({
  url, //api url
  body, //api body request
  columns, //column header
  browse_id,
  jsonUpd, //boolean
  browseData,
  //parent to child data transfer
  showPriorityBar,
}) => {
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    filter_value: "",
    chk_all: false,
  });
  const [browseListData, setBrowseListData] = useState([]);
  // const [columnDraggable, setColumnDraggable] = useState(null);
  const [columnSizing, setColumnSizing] = useState({});
  const [visibleColumns, setVisibleColumns] = useState({});
  const [totalRecord, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [jsonUpdate, setJsonUpdate] = useState(jsonUpd);
  const [excelRecord, setexcel] = useState([]);
  const [params, setParams] = useState({
    page_number: 0,
    page_size: 10,
    sort_column: "",
    sort_order: "no",
  });
  const getBrowseListData = async () => {
    setLoading(true);
    const obj = {
      ...globalFilter,
      ...body,
      browse_id: browse_id,
      user_id: user_id,
    };
    await CommonController.commonBrowseApiCallNew(url, obj, params, "node")
      .then((data) => {
        if (data.valid) {
          setLoading(false);
          setBrowseListData(data.data);
          setTotalRecords(data.totalRecords);
          setexcel(data.excelRecord);
          browseData(data.dataTransfer);
        }
      })
      .catch(() => {
        // showErrorToast(err);
      });
  };
  useEffect(() => {
    setJsonUpdate(jsonUpd);
  }, [jsonUpd]);
  const handleSearch = (e) => {
    if (e.target.value === "" && e.key === "Backspace") {
      setGlobalFilter({ ...globalFilter, [e.target.name]: e.target.value });
      setJsonUpdate(jsonUpdate + 1);
    }
    if (e.key === "Enter") {
      setGlobalFilter({ ...globalFilter, [e.target.name]: e.target.value });
      setJsonUpdate(jsonUpdate + 1);
    }
  };
  const handleShortData = (short) => {
    if (short.length > 0) {
      setParams({
        ...params,
        sort_column: short[0].field,
        sort_order: short[0].sort,
      });
    } else {
      setParams({
        ...params,
        sort_column: "",
        sort_order: "no",
      });
    }
  };
  useEffect(() => {
    getBrowseListData();
    // changeIcon();
  }, [body, jsonUpdate, params]);
  useEffect(() => {
    if (sorting.length > 0) {
      if (sorting[0].desc) {
        setParams({
          ...params,
          sort_column: sorting[0].id,
          sort_order: "desc",
        });
      } else {
        setParams({ ...params, sort_column: sorting[0].id, sort_order: "asc" });
      }
    }
  }, [sorting]);

  return (
    <Container fluid className="white_box">
      <Col md={11}>
        <div className="search_wrapper d-flex justify-content-between align-items-center gap-3 mb-3">
          <FormControl size="small" variant="outlined">
            <TextField
              fullWidth
              id="filter_value"
              size="small"
              onKeyUp={handleSearch}
              name="filter_value"
              // value={globalFilter.filter_value}
              variant="outlined"
              autoComplete="off"
              placeholder="Search"
            />
          </FormControl>
          <div
            style={{ flex: 1 }}
            className="d-flex justify-content-between align-items-center"
          >
            <TableExportButton excelData={excelRecord} fileName={``} />
            {showPriorityBar && (
              <ul className="priorityBar">
                <li>
                  <span style={{ backgroundColor: "green" }}></span>High
                </li>
                <li>
                  <span style={{ backgroundColor: "brown" }}></span>Medim
                </li>
                <li>
                  <span style={{ backgroundColor: "violet" }}></span>Low
                </li>
                <li>
                  <span style={{ backgroundColor: "red" }}></span>Over Due Date
                </li>
              </ul>
            )}
          </div>
        </div>
      </Col>

      <Col md={12}>
        <div className="table_grid">
          <MaterialReactTable
            layoutMode="grid"
            // enableEditing={true}
            enableResizing={true}
            columns={columns}
            data={browseListData}
            enableColumnActions={false}
            manualFiltering
            enableColumnFilterModes
            enablePagination={false}
            enableColumnFilters={false}
            // enableColumnDragging={true}
            enableGlobalFilter={false}
            enableSorting
            enableFullScreenToggle={false}
            // enableColumnOrdering
            enableColumnResizing
            enableHiding={true}
            onSortModelChange={(sort) => handleShortData(sort)}
            enableStickyHeader={true}
            manualSorting
            onSortingChange={setSorting}
            onColumnSizingChange={setColumnSizing}
            enableDensityToggle={true}
            initialState={{
              showColumnFilters: true,
              columnVisibility: visibleColumns,
              density: "compact",
            }} //
            onColumnVisibilityChange={setVisibleColumns}
            state={{
              sorting,
              columnVisibility: visibleColumns,
              columnSizing: columnSizing,
              isLoading: loading,
            }}
            // rowCount={totalRecord}
            muiTableBodyProps={{
              sx: {
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#e2e2e2 !important",
                },
                "& .MuiTableCell-root": {
                  borderLeft: "1px solid #cacaca",
                  borderBottom: "1px solid #cacaca",
                  overflowWrap: "break-word",
                  whiteSpace: "unset",
                  padding: "5px",
                },
                "& .Mui-TableHeadCell-Content-Wrapper": {
                  borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  overflowWrap: "break-word",
                  whiteSpace: "unset",
                },
                "& .MuiTableHead-root th": {
                  borderLeft: "1px solid rgba(224, 224, 224, 1)",
                },
              },
            }}
            muiTableContainerProps={{
              sx: { height: `calc(100vh - 246px)` },
            }}
          />
        </div>
      </Col>
      <PaginationCustom
        getParam={params}
        totalRecord={totalRecord}
        paramPage={setParams}
        jsonfilter={setJsonUpdate}
      />
    </Container>
  );
};
export default memo(CommonDataGrid);
