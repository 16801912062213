import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { MdMeetingRoom } from "react-icons/md";
import { NavLink, Outlet } from "react-router-dom";

export default function Buildings() {
  return (
    <>
      <div className="file_location_main_wrapper locationlevel2">
        <div className="tabs">
          <ul>
            <li>
              <NavLink
                to="/file-location/building1/room1"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 01</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building1/room2"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 02</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 03</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 04</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 05</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 06</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 07</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 08</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 09</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 10</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 11</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdMeetingRoom />
                <span>Room 12</span>
              </NavLink>
            </li>
          </ul>
          <div className="addBtn">
            <Button variant="none">
              <FaPlus /> Add Room
            </Button>
          </div>
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </>
  );
}
