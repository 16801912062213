import CommonDataGrid from "../../../components/CommonDataGrid";
import { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Autocomplete,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { CommonController } from "../../../_redux/common.controller";
import { FaEye } from "react-icons/fa";
import MainLayout from "../../../components/MainLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { AutoCompleteDropdown } from "../../../components/autoCompleteDropdown";
import { MdContentCopy } from "react-icons/md";
import { FaLink } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function DashboardCredential() {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    credential_category: "",
    credential_id: 0,
    credential_category_id: "",
    heading: "",
    url: "",
    user_name: "",
    password: "",
    registered_mobile: "",
    registered_email: "",
    remarks: "",
    credential_users: [],
  });
  const handleClose = () => {
    setShow(false);
    setview(false);
    setFormData({
      credential_id: 0,
      credential_category_id: "",
      heading: "",
      url: "",
      user_name: "",
      password: "",
      registered_mobile: "",
      registered_email: "",
      remarks: "",
      credential_users: [],
    });
  };
  const handleShow = () => setShow(true);
  const [valid, setvalid] = useState(false);
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add Credential",
  });
  const [validSubmit, setvalidSubmit] = useState(false);
  const [view, setview] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);
  const [companyList, setcompanyList] = useState([]);
  const [filter, setfilter] = useState(false);

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sr No.",
        minSize: 29,
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: "url",
        header: "Web Url",
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ justifyContent: "center" }}>
            <Link href={row.original.url} target="_blank">
              <FaLink
                size={15}
                style={{
                  cursor: "pointer",
                  color: "black",
                  display: "inline-block",
                  width: "10px",
                }}
                onClick={() => {
                  window.open(row.original.url, "_blank");
                }}
              />
            </Link>
          </div>
        ),
        enableColumnFilter: false,
        minSize: 40,
        size: 70,
      },
      {
        accessorKey: "credential_category",
        header: "Category",
        enableColumnFilter: false,
        // minSize: 88,
        minSize: 20,
        size: 80,
      },
      {
        accessorKey: "heading",
        header: "Heading",
        enableColumnFilter: false,
        // minSize: 110,
        minSize: 53,
        size: 75,
      },

      {
        accessorKey: "user_name",
        header: "User Name",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.user_name}
            <MdContentCopy
              size={15}
              className="ms-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                let a = row.original.user_name;
                navigator.clipboard.writeText(`${a}`);
                toast.success("Copied !", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }}
            />
            {/* <button
                style={{ padding: 5, color: "white", fontSize: 15 }}
                className="bg-success"
              ></button> */}
          </div>
        ),
        enableColumnFilter: false,
        minSize: 154,
        size: 171,
      },

      {
        accessorKey: "passowrd",
        header: "Passowrd",
        enableColumnFilter: false,
        minSize: 57,
        size: 103,
        Cell: ({ renderedCellValue, row }) => (
          <div className="showhide_password d-flex">
            <input
              type="password"
              value={row.original.password}
              disabled
              id={row.original.password}
              className="border-0 bg-transparent w-100 p-0"
            />
            <div className="d-flex align-items-center ps-2">
              <FaEye
                size={15}
                title="Show password"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  var x = document.getElementById(row.original.password);
                  if (x.type === "password") {
                    x.type = "text";
                  } else {
                    x.type = "password";
                  }
                }}
              />
              <MdContentCopy
                size={15}
                title="Copy password"
                style={{ cursor: "pointer", marginLeft: "20px" }}
                onClick={() => {
                  let a = row.original.password;
                  navigator.clipboard.writeText(`${a}`);
                  toast.success("Copied !", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }}
              />
            </div>
          </div>
        ),
      },

      // {
      //   accessorKey: "passowrd",
      //   header: "Passowrd",
      //   enableColumnFilter: false,
      //   minSize: 85,
      //   Cell: ({ renderedCellValue, row }) => (
      //     <div>
      //       <div>
      //         <button
      //           style={{ padding: 5, color: "white", fontSize: 15 }}
      //           className="bg-success"
      //           onClick={() => {
      //             let a = row.original.password;
      //             navigator.clipboard.writeText(`${a}`);
      //             toast.success("Copied !", {
      //               position: toast.POSITION.TOP_RIGHT,
      //             });
      //           }}
      //         >
      //           {row.original.password}
      //         </button>
      //       </div>
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "registered_mobile",
      //   header: "Registered Mobile",
      //   enableColumnFilter: false,
      //   minSize: 106,
      //   size: 139,
      // },
      // {
      //   accessorKey: "registered_email",
      //   header: "Registered Email",
      //   enableColumnFilter: false,
      //   minSize: 99,
      //   size: 182,
      // },

      // {
      //   accessorKey: " ",
      //   header: "Action",
      //   enableColumnFilter: false,
      //   minSize: 37,
      //   size: 85,
      //   Cell: ({ renderedCellValue, row }) => (
      //     <div>
      //       <FaPenToSquare
      //         onClick={() => {
      //           setview(false);
      //           handleEdit({
      //             id: row.original.credential_id,
      //             type: false,
      //             label: "Edit Credential ",
      //           });
      //         }}
      //         style={{ cursor: "pointer", color: "blue" }}
      //       />
      //       <FaEye
      //         onClick={() => {
      //           setview(true);
      //           handleEdit({
      //             id: row.original.credential_id,
      //             type: true,
      //             label: "Preview Credential",
      //           });
      //         }}
      //         style={{
      //           cursor: "pointer",
      //           margin: "0px 5px",
      //           color: "green",
      //         }}
      //       />{" "}
      //       <FaTrash
      //         onClick={() => handleDeleteRow(row.original.credential_id)}
      //         style={{ cursor: "pointer", color: "red" }}
      //       />
      //     </div>
      //   ),
      // },
    ],
    []
  );

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangetoggle = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const submitDoc = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminCredential/insert_credential",
        formData,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setview(true);
            setupdateGrid(updateGrid + 1);
            setFormData({
              credential_id: 0,
              credential_category_id: "",
              heading: "",
              url: "",
              user_name: "",
              password: "",
              registered_mobile: "",
              registered_email: "",
              remarks: "",
              credential_users: [],
            });
            handleClose();
            toast.success("New Credential Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminCredential/delete_credential",
        {
          credential_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Succesfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleEdit = (data) => {
    handleShow();
    const val = { ...data };
    val.valid = true;
    seteditData(val);

    try {
      CommonController.commonApiCallFilter(
        "quickAdminCredential/preview_credential",
        {
          credential_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formData };
          // setvalidSubmit(true);

          for (let key in formData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setFormData(tempData);
        }
      });
    } catch (err) {
      console(err);
    }
  };

  const cpList = (id) => {
    CommonController.commonApiCallFilter(
      "quickAdminCredential/list_credential_users",
      {
        user_id: id,
      },
      "post",
      "node"
    ).then((data) => {
      if (data.valid) {
        setcompanyList(data.data);
      }
    });
  };

  const handleBodyFilter = (data) => {
    setFormData(data);
    setfilter(false);
  };

  useEffect((type) => {
    cpList();
  }, []);

  return (
    <MainLayout
      pageName="Credential"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Add Credential" });
      }}
      isAddBtnVisible={false}
    >
      <CommonDataGrid
        url={"quickAdminCredential/browse_credential"}
        columns={gridColumn}
        body={""}
        // bodyData={() => setvalid(false)}
        jsonUpd={updateGrid}
        gridHeight={370}
        bodyData={handleBodyFilter}
      />
      <ToastContainer />
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="add_table_data"
        centered
      >
        <Modal.Body>
          <h4>{editData.label}</h4>
          <Form className="mt-3">
            <Row>
              <Col md={6} className="mb-3">
                <AutoCompleteDropdown
                  url={"quickAdminCredential/list_credential_category"}
                  handleDataChange={(val) =>
                    setFormData({
                      ...formData,
                      credential_category_id: val.credential_category_id,
                      credential_category: val.credential_category,
                    })
                  }
                  labelName={"Credential Category"}
                  valueInput={formData.credential_category}
                  objLevel={"credential_category"}
                  disabled={view}
                  // multiple
                />
              </Col>

              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Heading"
                  variant="outlined"
                  className="w-100"
                  name="heading"
                  value={formData.heading}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              {/* <Col md={6} className="mb-3">
								<TextField
									size="small"
									label=" Web Url"
									variant="outlined"
									className="w-100"
									onChange={handleChange}
									disabled={view}
								/>	name="url"
									value={formData.url}
								
							</Col> */}

              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Web Url"
                  variant="outlined"
                  className="w-100"
                  name="url"
                  value={formData.url}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="User Name"
                  variant="outlined"
                  className="w-100"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Password"
                  variant="outlined"
                  className="w-100"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Registered Email"
                  variant="outlined"
                  className="w-100"
                  name="registered_email"
                  value={formData.registered_email}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Registered Mobile Number"
                  variant="outlined"
                  className="w-100"
                  name="registered_mobile"
                  value={formData.registered_mobile}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="Remarks"
                  variant="outlined"
                  className="w-100"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>

              <Col md={6} className="mb-3">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={companyList}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  size="small"
                  value={formData.credential_users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Credential Users"
                      placeholder="credential_users"
                    />
                  )}
                  onChange={(e, value) =>
                    setFormData({ ...formData, credential_users: value })
                  }
                />
              </Col>
              <Col md={6}>
                {editData.valid ? (
                  <Col md={2} className="d-flex">
                    <div className="text-center">
                      <FormControlLabel
                        control={<Switch />}
                        label="Disable User"
                        onChange={handleChangetoggle}
                        checked={formData.disable}
                        name="disable"
                        disabled={view}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 mt-1 text-right">
            {!editData.type ? (
              <Button
                variant="success"
                size="sm"
                onClick={submitDoc}
                className="me-3"
              >
                {editData.valid ? "Update" : "Save"}
              </Button>
            ) : (
              ""
            )}
            <Button variant="danger" size="sm" onClick={handleClose}>
              Cancel
            </Button>
          </div>s
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
}
