import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { MdOutlineDoorSliding } from "react-icons/md";
import { NavLink, Outlet } from "react-router-dom";

export default function Room() {
  return (
    <>
      <div className="file_location_main_wrapper locationlevel3">
        <div className="tabs">
          <ul>
            <li>
              <NavLink
                to="/file-location/building1/room1/almirah1"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdOutlineDoorSliding />
                <span>Almirah 01</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building1/room2"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdOutlineDoorSliding />
                <span>Almirah 02</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building1/room3"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdOutlineDoorSliding />
                <span>Almirah 03</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building1/room4"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdOutlineDoorSliding />
                <span>Almirah 04</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building1/room5"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdOutlineDoorSliding />
                <span>Almirah 05</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building1/room6"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdOutlineDoorSliding />
                <span>Almirah 06</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/file-location/building1/room7"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <MdOutlineDoorSliding />
                <span>Almirah 07</span>
              </NavLink>
            </li>
          </ul>
          <div className="addBtn">
            <Button variant="none">
              <FaPlus /> Add Almirah
            </Button>
          </div>
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </>
  );
}
