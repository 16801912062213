import { NavLink, Outlet } from "react-router-dom";
import MainLayout from "../../../components/MainLayout";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FaBuilding, FaPlus } from "react-icons/fa";
import { useState } from "react";
import { TextField } from "@mui/material";

export default function FileLocation() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <MainLayout
        pageName="File Location"
        isOpen={null}
        isAddBtnVisible={false}
      >
        <Container fluid>
          <Row>
            <Col md={12}>              
              <ul className="location_levels">
                <li>
                  <span>Level 1</span>
                  Building / Office
                </li>
                <li>
                  <span>Level 2</span>
                  Floor / Room
                </li>
                <li>
                  <span>Level 3</span>
                  Almirah / Table
                </li>
                <li>
                  <span>Level 4</span>
                  Drawer / Rack
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="file_location_main_wrapper locationlevel1">
                <div className="tabs">
                  <ul>
                    <li>
                      <NavLink
                        to="/file-location/building1"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 01</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building2"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 02</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 03</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 04</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 05</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 06</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 07</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 08</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 09</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 10</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 11</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/file-location/building3"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FaBuilding />
                        <span>Building 12</span>
                      </NavLink>
                    </li>
                  </ul>
                  <div className="addBtn">
                    <Button variant="none" onClick={handleShow}>
                      <FaPlus /> Add Building
                    </Button>
                  </div>
                </div>
                <div className="content">
                  <Outlet />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </MainLayout>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <h6>Add Building / Office</h6>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <TextField
                  label="Name"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value=""
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  label="Place"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value=""
                />
              </Col>
              <Col md={12} className="d-flex gap-2 justify-content-end">
                <Button variant="danger" size="sm" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="success" size="sm" onClick={handleClose}>
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
