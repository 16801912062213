import { Carousel, Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CommonController } from "../../_redux/common.controller";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader";
import { nodeUrl } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function AddDocumentList({ filterData }) {
  const location = useLocation();
  const [sharingdata, setsharingdata] = useState({
    shareId: "",
    document_id: [],
  });
  const [sharedselect, setsharedselect] = useState("");
  const [page_number, setpage_number] = useState(1);

  const [browseData, setbrowseData] = useState([]);
  const [loader, setloader] = useState(true);
  const [scrollTop, setScrollTop] = useState(800);
  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop >= scrollTop) {
      BrowseDoc(page_number);
      setpage_number(page_number + 1);
      setScrollTop(scrollTop + 800);
    }
  };
  const [fileList, setFileList] = useState([]);
  const submitShare = async (docid, sharedid) => {
    setsharingdata({ ...sharingdata, document_id: docid, shareId: sharedid });

    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDashboard/insert_document_share_documents",
        sharingdata,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            toast.success("New Document Category Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };

  const getFileListFullData = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/file_list",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setFileList(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const BrowseDoc = async (page) => {
    // setloader(true);

    const obj = { ...filterData };
    obj.share_id = location.state?.share_id?location.state?.share_id:null;

    try {
      await CommonController.commonApiCallFilter(
        `quickAdminDashboard/browse_share_document_view?page_number=${page}`,
        obj,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            if (page === 0) {
              setbrowseData(result.data);
              setsharedselect(result.data.shared);
            } else {
              setbrowseData((val) => [...val, ...result.data]);
            }
            setloader(false);
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    BrowseDoc(0);
  }, [filterData]);
  useEffect(() => {
    getFileListFullData();
  }, []);

  // const Sameer = () => {
  //   useEffect(() => {}, [browseData]);
  // };

  return (
    <>
      <ToastContainer />
      {/* <MainLayout pageName="Add Document" isAddBtnVisible={false}> */}
        {loader ? (
          <Loader />
        ) : (
          <div className="table_view_main_wrapper">
            {/* <div className="d-flex align-items-center justify-content-between ps-2 pe-2 gap-3">
              <div className="rounded-2 p-2 bg-white w-100 d-flex align-items-center gap-3">
                <FaUser /> {location?.state?.contact_person}
              </div>
              <div className="rounded-2 p-2 bg-white w-100 d-flex align-items-center gap-3">
                <FaEnvelope /> {location?.state?.email}
              </div>
              <div className="rounded-2 p-2 bg-white w-100 d-flex align-items-center gap-3">
                <MdCall /> {location?.state?.mobile}
              </div>
              <div className="rounded-2 p-2 bg-white w-100 d-flex align-items-center gap-3">
                <FaBuilding /> {location?.state?.company_name}
              </div>
              <div>
                <Button size="sm" onClick={submitShare}>
                  Done
                </Button>
              </div>
              <br />
            </div> */}

            <Container
              onScroll={handleScroll}
              fluid
              className="grid_view_scroll_wrap add_document"
            >
              <Row>
                {browseData.length > 0 &&
                  browseData.map((item) => (
                    <Col md={6}>
                      <div className="bg-white rounded upload_grid_view_columns">
                        <Row>
                          <Col xs={12}>
                            <h5>
                              {item.ID}-{item.document_type}/
                              {item.document_category}
                            </h5>
                          </Col>
                          <Col xs={6}>
                            <div className="document_preview_image rounded">
                              <Carousel interval={1000}>
                                {item.viewAttachement &&
                                  item.viewAttachement.map((attach) => (
                                    <Carousel.Item>
                                      <iframe
                                        src={`${nodeUrl}${attach.path}`}
                                        rounded
                                        className="w-100 h-100"
                                      />
                                      {/* <img src={`${nodeUrl}${attach.attach_path}`}
                                      rounded
                                      className="w-100 h-100"/> */}
                                    </Carousel.Item>
                                  ))}
                              </Carousel>
                            </div>
                          </Col>
                          <Col xs={6} className="ps-0">
                            <div className="details rounded">
                              <div className="bg-lightgray">
                                <h6>Files</h6>

                                <div>
                                  <ul class="files-list">
                                    {item.viewFile.length > 0 &&
                                      item.viewFile.map((file) => (
                                        <li>
                                          <b>{file.file_name}</b>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="bg-lightgray">
                                <h6>Category Type</h6>
                                <div class="files">
                                  <ul class="files-list">
                                    {item.viewParameter.length > 0 &&
                                      item.viewParameter.map((parameter) => (
                                        <>
                                          <li>
                                            <b> {parameter.field_name}</b>:{""}
                                            {parameter.field_value}
                                          </li>
                                        </>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Col>
                          {/* <Col xs={12}>
                            <ul className="bottom_buttons">
                              <li>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    submitShare(
                                      item.document_id,
                                      location.state.share_id
                                    );
                                    // setbrowseData({
                                    //   ...browseData,
                                    //   shared: true,
                                    // });
                                  }}
                                >
                                  {!item.shared ? "Shared" : "Add to Share"}
                                </Button>
                              </li>
                            </ul>
                          </Col> */}
                        </Row>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Container>
          </div>
        )}
      {/* </MainLayout> */}
    </>
  );
}
