import MainLayout from "../../components/MainLayout";

import { useMemo, useState } from "react";
import moment from "moment";
import { CommonController } from "../../_redux/common.controller";
import { useEffect } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { nodeUrl } from "../../config";
import { CgAttachment } from "react-icons/cg";
import { Link, Outlet, useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [updateGrid, setupdateGrid] = useState(0);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("tasks");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [openApproved, setOpenApproved] = useState(false);
  const [openMarkComplete, setopenMarkComplete] = useState(false);
  const [openPendingTask, setopenPendingTask] = useState(false);

  const handleCloseApproved = () => setOpenApproved(false);

  const handleMarkcomplete = () => setopenMarkComplete(false);

  const handlePendingCom = () => setopenPendingTask(false);
  const [assigndata, setassigndata] = useState([]);

  const [reassignTask, setreassignTask] = useState({
    task_id: "",
    assignby_id: "",
    assignto_id: "",
    remarks: "",
  });

  const [markComplete, setmarkComplete] = useState({
    task_id: "",
    mark_complete: false,
    remarks: "",
  });

  const [pendingTask, setpendingTask] = useState({
    task_id: "",
    mark_review: false,
    remarks: "",
  });

  const [reassignData, setreassignData] = useState([]);

  const handleCheckBox = (event) => {
    setmarkComplete({
      ...markComplete,
      [event.target.name]: event.target.checked,
    });
  };

  const handleButtonClick = (task_id) => {
    const url = `${nodeUrl}${task_id}`;
    const windowOptions = "width=800,height=600,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };
  const handleCheckBoxPending = (event) => {
    setpendingTask({
      ...pendingTask,
      [event.target.name]: event.target.checked,
    });
  };

  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [lengthyRemarks, setLengthyRemarks] = useState("");
  const [popupHead, setpopupHead] = useState("");

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "color",
        header: "",
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <div
              style={{
                backgroundColor: renderedCellValue,
                fontSize: "0",
                width: "20px",
                height: "100%",
              }}
            ></div>
          );
        },
        width: 20,
        minSize: 20,
        size: 20,
      },
      {
        accessorKey: "id",
        header: "S.N.",
        minSize: 24,
        size: 50,
        enableColumnFilter: false,
      },

      {
        accessorKey: "date",
        header: "Due Date",
        width: 200,
        enableColumnFilter: false,
        minSize: 55,
        size: 88,
        Cell: ({ renderedCellValue, row }) => {
          return moment(renderedCellValue).format("DD/MM/yyyy");
        },
      },

      {
        accessorKey: "task",
        header: "Task",
        width: 400,
        enableColumnFilter: false,
        minSize: 24,
        size: 180,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <div className="d-flex align-items-start ">
              <div
                style={{
                  color:
                    row.original.priority === "High"
                      ? "green"
                      : row.original.priority === "Medium"
                      ? "brown"
                      : row.original.priority === "Low"
                      ? "violet"
                      : "transparent",
                  maxWidth: "150px",
                  minWidth: "15px",
                  height: "15px",
                  marginRight: "7px",
                  marginTop: "2px",
                  borderRadius: "3px",
                  textShadow: "1px 1px 1px rgb(0 0 0 / 20 %)",
                }}
                onClick={() => {
                  setLengthyRemarks(renderedCellValue);
                  setShowRemarksModal(true);
                  setpopupHead("Task");
                }}
              >
                <span className="flex-1 fw-bold text-decoration-underline remark_lengthy">
                  {row.original.task}
                </span>
              </div>
            </div>
          );
        },
      },
      // {
      // 	accessorKey: "task_category",
      // 	header: "Task Category",
      // 	width: 400,
      // 	enableColumnFilter: false,
      // 	minSize: 85,
      // },

      {
        accessorKey: "remarks",
        header: "Remarks",
        width: 400,
        enableColumnFilter: false,
        minSize: 42,
        size: 214,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
              setpopupHead("Remarks");
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },

      {
        accessorKey: "attachment",
        header: "Attach.",
        width: 400,
        enableColumnFilter: false,
        minSize: 62,
        size: 50,
        Cell: ({ row }) => (
          <div
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          // }}
          >
            {/* <Button
                className="column_btn"
              onClick={(e) => handleButtonClick(row.original.attachment)}
            >
              <FaEye />
            </Button> */}

            <CgAttachment
              className="sameer"
              onClick={(e) => handleButtonClick(row.original.attachment)}
            />
          </div>
        ),
      },
      {
        accessorKey: "reassign",
        header: "Reassign",
        enableColumnFilter: false,
        minSize: 43,
        size: 102,
        Cell: ({ renderedCellValue, row }) => (
          <div className=" text-center w-100 fw-bold text-decoration-underline ">
            {row.original.mark_complete ? (
              <>
                <label
                  size="sm"
                  className="table_grid_btn sameer"
                  onClick={() => handleAssign(row.original)}
                  disabled
                >
                  {row.original.reassign}
                </label>
              </>
            ) : (
              <>
                <label
                  size="sm"
                  className="table_grid_btn text-primary sameer "
                  onClick={() => handleAssign(row.original)}
                >
                  {row.original.reassign}
                </label>
              </>
            )}
          </div>
        ),
      },

      {
        accessorKey: "MarkCompleted",
        header: "Mark Complete By",
        enableColumnFilter: false,
        minSize: 89,
        size: 111,
        Cell: ({ renderedCellValue, row }) => (
          <div className="text-center w-100 fw-bold text-decoration-underline ">
            {row.original.mark_complete ? (
              <>
                <label
                  onClick={() => {
                    handleComplete(row.original);
                  }}
                  className={`${
                    row.original.mark_complete
                      ? "text-success "
                      : "text-danger "
                  } table_grid_btn sameer`}
                  disabled
                >
                  {row.original.complete_by}
                </label>
              </>
            ) : (
              <>
                <label
                  onClick={() => {
                    handleComplete(row.original);
                  }}
                  className={`${
                    row.original.mark_complete
                      ? "text-success "
                      : "text-danger "
                  } table_grid_btn sameer`}
                >
                  {row.original.complete_by}
                </label>
              </>
            )}
          </div>
        ),
      },

      {
        accessorKey: "assign_by",
        header: "Assign By",
        width: 400,
        enableColumnFilter: false,
        minSize: 58,
        size: 110,
      },

      {
        accessorKey: "assign_to",
        header: "Assign To",
        width: 400,
        enableColumnFilter: false,
        minSize: 56,
        size: 110,
        // Cell: ({ renderedCellValue, row }) => (
        //   <div
        //     className="remark_lengthy"
        //     onClick={() => {
        //    
        //       setLengthyRemarks(renderedCellValue);
        //       setShowRemarksModal(true);
        //       setpopupHead("Assin To");
        //     }}
        //   >
        //     {renderedCellValue}
        //   </div>
        // ),
      },
    ],
    []
  );

  const handleChangeTaskReassign = (event) => {
    setreassignTask({
      ...reassignTask,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeMarkComplete = (event) => {
    setmarkComplete({
      ...markComplete,
      [event.target.name]: event.target.value,
    });
  };

  const handlePendingComplete = (event) => {
    setpendingTask({
      ...pendingTask,
      [event.target.name]: event.target.value,
    });
  };

  const handleAssign = (e) => {
    setreassignTask({
      ...reassignTask,
      assignby_id: e.add_user_id,
      task_id: e.task_id,
      assignto_id: e.assignto_id,
      task_head: e.task,
      // remark: e.target.remark,
    });

    try {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/browse_task_reassign",
        {
          task_id: e.task_id,
        },
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setreassignData(result.data);
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }

    setOpenApproved(true);
  };

  const handleComplete = (e) => {
    setmarkComplete({
      ...markComplete,

      task_id: e.task_id,
      task_head: e.task,
    });
    setopenMarkComplete(true);
  };

  const handlePending = (e) => {
    setpendingTask({
      ...pendingTask,

      task_id: e.task_id,
      task_head: e.task,
    });
    setopenPendingTask(true);
  };

  const assignList = (id) => {
    CommonController.commonApiCallFilter(
      "quickAdminDocMangement/list_task_assign",
      {
        user_id: id,
      },
      "post",
      "node"
    ).then((data) => {
      if (data.valid) {
        setassigndata(data.data);
      }
    });
  };

  const submitMarkCompleteTask = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_task_complete",
        markComplete,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            handleMarkcomplete();
            setupdateGrid(updateGrid + 1);
            toast.success("Task Marking Done !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const submitReassignTask = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_task_reassign",
        reassignTask,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            handleCloseApproved();
            setupdateGrid(updateGrid + 1);
            toast.success("Task Reassigned !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const submitpendingTask = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_task_review",
        pendingTask,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            handlePendingCom();
            setupdateGrid(updateGrid + 1);
            toast.success("Done !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect((type) => {
    assignList();
  }, []);

  return (
    <MainLayout
      pageName="Dashboard"
      isAddBtnVisible={false}
      isDashboardTabsVisible={true}
    >
       {/* <Outlet /> */}
      <div className="task_grid">
        {/* <CommonDataGrid
          url={"quickAdminDashboard/dashboard_pending_task"}
          columns={gridColumn}
          body={""}
          // bodyData={() => setvalid(false)}
          // jsonUpd={updateGrid}
          gridHeight={370}
          // bodyData={handleBodyFilter}
          showPriorityBar={true}
        />

        <ToastContainer /> */}
      </div>

      {/* <Modal show={showRemarksModal} onHide={() => setShowRemarksModal(false)}>
        <Modal.Header closeButton>
          <h6 className="fw-bold m-0">{popupHead}</h6>
        </Modal.Header>
        <Modal.Body>{lengthyRemarks}</Modal.Body>
      </Modal> */}

      {/* <>
        <Modal
          size="lg"
          show={openApproved}
          onHide={handleCloseApproved}
          className="sopos__modal add_table_data"
          dialogClassName="w-100"
          centered
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0 lh-1">ReAssign Task</h4>
              <h6 className="m-0 lh-1 fst-italic">
                Select User to Reassign the Task
              </h6>
            </div>
            <Badge bg="primary">Task Name : {reassignTask.task_head}</Badge>
            <Form>
              <Row>
                <Col xs={12} className="mt-3 mb-3">
                  <AutoCompleteDropdown
                    url={"quickAdminDocMangement/list_user_task_reassign"}
                    handleDataChange={(val) =>
                      setreassignTask({
                        ...reassignTask,
                        assignto_id: val.user_id,
                        assignto_name: val.full_name,
                      })
                    }
                    labelName={"Reassign To"}
                    valueInput={reassignTask.assignto_name}
                    objLevel={"full_name"}
                    className="mb-3"
                    // disabled={view}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <TextareaAutosize
                    name="remarks"
                    size="small"
                    value={reassignTask.remarks}
                    onChange={handleChangeTaskReassign}
                    className="w-100"
                    style={{ height: "120px" }}
                    placeholder="Remarks"
                    // disabled={view}
                  />
                </Col>
                <Col xs={12} className="d-flex gap-2 align-items-center">
                  <Button
                    variant="success"
                    size="sm"
                    className="global__buttons"
                    onClick={submitReassignTask}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handleCloseApproved}
                    size="sm"
                    className="global__buttons"
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </Col>
                <br />
                <Col md={12} className="mt-3">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Assign By</th>
                        <th>Assign To</th>
                        <th>Remarks</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    {reassignData.length > 0 &&
                      reassignData.map((item) => (
                        <tbody>
                          <tr>
                            <td>{item.assignby}</td>
                            <td>{item.assignto}</td>
                            <td>{item.remarks}</td>
                            {/* <td>{item.datetime}</td> */}
      {/* <td>
                              {moment(item.datetime).format("DD/MM/yyyy")}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </Table>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </>  */}
      {/* REASSIGN TASK POPUP START  */}
      {/* MARK COMPLETE TASK POPUP  START*/}
      {/* <>
        <Modal
          show={openMarkComplete}
          onHide={handleMarkcomplete}
          className="sopos__modal add_table_data"
          dialogClassName="w-100"
          centered
          size="md"
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0 lh-1">Mark Complete Task</h4>
            </div>
            <Badge bg="primary">Task Name : {reassignTask.task_head}</Badge>
            <Form>
              <Row>
                <Col xs={12} className="mt-3 mb-3">
                  <FormControlLabel
                    control={<Checkbox color="success" />}
                    // disabled={!editData.type ? false : true}
                    label="Mark Complete"
                    onChange={handleCheckBox}
                    checked={markComplete.mark_complete}
                    name="mark_complete"
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <TextareaAutosize
                    name="remarks"
                    size="small"
                    value={markComplete.remarks}
                    onChange={handleChangeMarkComplete}
                    className="w-100"
                    style={{
                      height: "120px",
                    }}
                    placeholder="Remarks"
                    // disabled={view}
                  />
                </Col>
                <Col xs={12} className="d-flex align-items-center gap-2">
                  <Button
                    variant="success"
                    className="global__buttons"
                    onClick={submitMarkCompleteTask}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handleMarkcomplete}
                    className="global__buttons"
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </> */}
      {/* MARK COMPLETE TASK POPUP  */}
      {/* <>
        <Modal
          show={openPendingTask}
          onHide={handlePendingCom}
          className="sopos__modal add_table_data"
          dialogClassName="w-100"
        >
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0 lh-1">Pending task</h4>
              <h6 className="m-0 lh-1 fst-italic">Mark Review/Close</h6>
            </div>
            <Badge bg="primary">Task Name : {reassignTask.task_head}</Badge>
            <Form>
              <Row>
                <Col xs={12} className="mb-3">
                  <FormControlLabel
                    control={<Checkbox color="success" />}
                    // disabled={!editData.type ? false : true}
                    label="Mark Review"
                    onChange={handleCheckBoxPending}
                    checked={pendingTask.mark_review}
                    name="mark_review"
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <TextareaAutosize
                    name="remarks"
                    size="small"
                    value={pendingTask.remarks}
                    onChange={handlePendingComplete}
                    className="w-100"
                    style={{ height: "120px" }}
                    placeholder="Remarks"
                  />
                </Col>
                <Col xs={12} className="d-flex align-items-center gap-2">
                  <Button
                    variant="success"
                    className="global__buttons"
                    onClick={submitpendingTask}
                    size="sm"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handlePendingCom}
                    className="global__buttons"
                    variant="danger"
                    size="sm"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </> */}
      {/* outlet for task and my docs and cradential */}
    </MainLayout>
  );
}
