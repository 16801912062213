import { Button, Col, Form, Row } from "react-bootstrap";
import LoginLayout from "./LoginLayout";
import { Link } from "react-router-dom";
import { useState } from "react";
import { authUser } from "../../components/common";
import { userService } from "../../services";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autocomplete, TextField } from "@mui/material";

import Box from "@mui/material/Box";

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [authParams, setAuthParams] = useState({
    user_name: "",

    otp: "",
  });
  const [showOTP, setShowOTP] = useState(false);
  const handleGetOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await userService.login("auth/login", {
        user_name: authParams.user_name,
      });
      if (response.data.valid) {
        // showSucessToast(response.data);
        setShowOTP(true);
      } else {
        toast.error("Please Enter Valid Number !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const submitSign = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await userService.login("auth/checkotp", authParams);
      if (response.data.valid) {
        authUser(response.data.token);
        window.location.reload();
      } else {
        toast.error("Wrong OTP !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    setAuthParams({ ...authParams, [event.target.name]: event.target.value });
  };
  return (
    <LoginLayout>
      <Row>
        <ToastContainer />

        <Col md={12} className="text-center">
          <h4>{!showOTP ? "Sign In" : "Enter OTP"}</h4>
          <p>
            {!showOTP
              ? "Enter your mobile (without Country Code) no Or email to login"
              : "Verify OTP"}
          </p>
        </Col>
        <Col md={12} className="mt-2">
          <Form onSubmit={showOTP ? submitSign : handleGetOtp}>
            {!showOTP ? (
              <Form.Group className="mb-3">
                <Form.Control
                  type="tel"
                  name="user_name"
                  placeholder="Enter Mobile No. Or Email"
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              <Form.Group className="mb-3">
                <Form.Control
                  type="number"
                  name="otp"
                  value={authParams.otp}
                  placeholder="Enter OTP"
                  onChange={handleChange}
                />
              </Form.Group>
            )}
            <Button
              variant="primary"
              type="submit"
              // onClick={() => navigate("/enter-otp")}
            >
              {loading ? "Please wait.." : showOTP ? "Submit" : "Send OTP"}
            </Button>
          </Form>
        </Col>
        <Col md={12} className="text-center mt-3">
          Don't have account? <Link to="/sign-up">Sign Up</Link>
        </Col>
      </Row>
    </LoginLayout>
  );
}
