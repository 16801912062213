import React from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import {
  FaBuilding,
  FaEnvelope,
  FaLink,
  FaPhone,
  FaPlus,
  FaRegWindowClose,
  FaShare,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { TextField } from "@mui/material";
import { CommonController } from "../../_redux/common.controller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { MdContentCopy } from "react-icons/md";

const SharingDashboard = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [browseData, setbrowseData] = useState([]);
  const [formData, setFormData] = useState({
    share_id: 0,
    company_name: "",
    contact_person: "",
    mobile: "",
    email: "",
    share_url: "",
  });

  const [generateList, setgenerateList] = useState([
    {
      sr_no: 1,
    },
  ]);

  //   function randomStr(len, arr) {
  //     let ans = "";
  //     for (let i = len; i > 0; i--) {
  //       ans += arr[Math.floor(Math.random() * arr.length)];
  //     }
  //     setFormData({ ...formData, share_url: "quickAdmin.in/" + ans });
  //   }

  const submitDoc = async () => {
    try {
      //   randomStr(10, "1234567890abcdefghijk");

      const obj = { ...formData };
      obj.share_url = `quickAdmin.in/${new Date().valueOf()}`;
      await CommonController.commonApiCallFilter(
        "quickAdminDashboard/insert_document_share",
        obj,
        "post",
        "node"
      ).then((result) => {
        if (result.valid) {
          setFormData({
            share_id: 0,
            company_name: "",
            contact_person: "",
            mobile: "",
            email: "",
            share_url: "",
          });
          addGenerateList();
          handleClose();
          shareformData();
          toast.success("New Share Member Added !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const shareformData = () => {
    CommonController.commonApiCallFilter(
      "quickAdminDashboard/browse_document_share",
      {
        user_id: 0,
      },
      "post",
      "node"
    ).then((data) => {
      if (data.valid) {
        setbrowseData(data.data);
      }
    });
  };

  const addGenerateList = () => {
    const values = [...generateList];
    values.push({
      sr_no: browseData.length + 1,
    });
    setbrowseData(values);
  };

  const handleClose = () => {
    setShow(false);
    setShowForm(false);
    const handleOpen = () => {
      setShow(true);
      setShowForm(true);
    };
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminDashboard/delete_document_share",
        {
          share_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Succesfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleClose();
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };

  useEffect(() => {
    shareformData();
  }, []);

  return (
    <>
      <Row>
        <Col className="text-end">
          <Button
            onClick={() => {
              setShow(true);
              shareformData();
            }}
            variant="success"
            size="sm"
            className=" ms-auto d-flex gap-2 align-items-center"
            style={{
              fontSize: "10px",
              padding: "2px 5px",
              borderRadius: "5px",
            }}
          >
            <FaShare />
            Share Members{" "}
          </Button>
        </Col>
      </Row>
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <h5 className="fw-bold">Lists</h5>
            </Col>
            <Col md={8}>
              <div className="share_list_wrapper">
                {browseData.length > 0 &&
                  browseData.map((item) => {
                    return (
                      <div className="item">
                        <span className="srno">{item.sr_no}</span>
                        <span
                          className="closeicon"
                          // onClick={() => window.confirm("Are you sure?")}
                          onClick={() => handleDeleteRow(item.share_id)}
                        >
                          <FaRegWindowClose />
                        </span>
                        <Row className="align-items-end">
                          <Col xs={12} className="name">
                            {item.contact_person}
                          </Col>
                          <Col xs={6} className="info">
                            <Row>
                              <Col className="mb-2">
                                <p></p>
                                <p>
                                  <FaPhone /> {item.mobile}
                                </p>
                                <p>
                                  <FaEnvelope /> {item.email}
                                </p>
                                <p>
                                  <FaBuilding />
                                  {item.company_name}
                                </p>
                              </Col>
                              <Col md={12}>
                                <Link
                                  to="/confirm-otp"
                                  className="url d-flex align-items-center gap-2"
                                  target="_blank"
                                  onClick={() =>
                                    window.open(
                                      `${window.location.origin}/confirm-otp/contact_person/${item.contact_person}/mobile/${item.mobile}`
                                    )
                                  }
                                >
                                  <FaLink size={10} />
                                  <span style={{ flex: 1 }} target="_blank">
                                    {item.share_url}
                                  </span>
                                  <MdContentCopy
                                    size={15}
                                    title="Copy password"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      let a = item.share_url;
                                      navigator.clipboard.writeText(`${a}`);
                                      toast.success("Copied !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                      });
                                    }}
                                  />
                                </Link>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            className="d-flex align-items-center justify-content-end gap-1"
                          >
                            <Row>
                              <Col md={12}>
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={() => {
                                    navigate("/add-document", {
                                      state: item,
                                      // userData: item,
                                    });
                                  }}
                                >
                                  <FaPlus /> Add document
                                </Button>
                              </Col>
                              {/* <Col md={12}>
                                  <div className="share_tab">
                                    <span>
                                      <FaShareAlt />
                                      Share
                                    </span>
                                    <ul>
                                      <li>
                                        <Badge pill className="whatsapp">
                                          <FaWhatsapp />
                                        </Badge>
                                      </li>
                                      <li>
                                        <Badge pill className="facebook">
                                          <FaFacebook />
                                        </Badge>
                                      </li>
                                      <li>
                                        <Badge pill className="google">
                                          <FaGoogle />
                                        </Badge>
                                      </li>
                                      <li>
                                        <Badge pill className="insta">
                                          <FaInstagram />
                                        </Badge>
                                      </li>
                                    </ul>
                                  </div>
                                </Col> */}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </div>
            </Col>

            <Col md={4}>
              {showForm ? (
                <Form>
                  <Row>
                    <Col md={12}>
                      <h5 className="fw-bold mb-3">Details</h5>
                    </Col>
                    <Col md="12">
                      <Form.Group className="mb-3">
                        <TextField
                          size="small"
                          label="Enter name"
                          variant="outlined"
                          className="w-100"
                          name="contact_person"
                          value={formData.contact_person}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>
                    </Col>

                    <Col md="12">
                      <Form.Group className="mb-3">
                        <TextField
                          size="small"
                          label="Enter mobile no."
                          variant="outlined"
                          className="w-100"
                          name="mobile"
                          value={formData.mobile}
                          onChange={(e) => handleChange(e)}
                          type="number"
                        />
                      </Form.Group>
                    </Col>

                    <Col md="12">
                      <Form.Group className="mb-3">
                        <TextField
                          size="small"
                          label="Enter email"
                          variant="outlined"
                          className="w-100"
                          name="email"
                          value={formData.email}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group className="mb-3">
                        <TextField
                          size="small"
                          label="Enter Company"
                          variant="outlined"
                          className="w-100"
                          name="company_name"
                          value={formData.company_name}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="success"
                    size="sm"
                    onClick={submitDoc}
                    className="me-2"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => setShowForm(false)}
                  >
                    Cancel
                  </Button>
                </Form>
              ) : (
                <div
                  className="share_list_right_column"
                  onClick={() => setShowForm(true)}
                >
                  <img
                    src={require("../../assets/images/add-doc.png")}
                    className="w-100"
                    alt=""
                  />
                  <span>Add Share Members</span>
                </div>
              )}
            </Col>
            <ToastContainer />
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SharingDashboard;
