import MainLayout from "../../components/MainLayout";
import CommonDataGrid from "../../components/CommonDataGrid";
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Checkbox, FormControlLabel, Switch, TextField } from "@mui/material";
// import ColorPicker from "../../components/ColorPicker";
import { CommonController } from "../../_redux/common.controller";
import { AutoCompleteDropdown } from "../../components/autoCompleteDropdown";
import { FaPenToSquare } from "react-icons/fa6";
import { FaEye, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { Saturation, Hue } from "react-color-palette";

export default function FileMaster() {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    file_id: 0,
    file_no: "",
    file_name: "",
    file_category_id: "",
    color_code: "",
    pysical_file_no: "",
    company_id: [],
    disable: true,
    user_id: 11,
    file_category: "",
    company_name: "",
  });
  const handleClose = () => {
    setShow(false);
    setview(false);
    setFormData({
      file_id: 0,
      file_no: "",
      file_name: "",
      file_category_id: "",
      color_code: "",
      pysical_file_no: "",
      company_id: [],
      disable: true,
      user_id: 11,
      file_category: "",
      company_name: "",
    });
  };
  const handleShow = () => setShow(true);
  const [valid, setvalid] = useState(false);
  const [editData, seteditData] = useState({
    valid: false,
    label: "Add File Master",
  });

  const [fileList, setfileList] = useState([]);
  const [view, setview] = useState(false);
  const [updateGrid, setupdateGrid] = useState(0);
  const [validSubmit, setvalidSubmit] = useState(false);
  const [companyList, setcompanyList] = useState([]);
  const [filter, setfilter] = useState(false);
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [lengthyRemarks, setLengthyRemarks] = useState("");

  const gridColumn = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sr No.",
        minSize: 29,
        size: 52,
        enableColumnFilter: false,
      },
      {
        accessorKey: "file_category",
        header: "File Category",
        size: 125,
        enableColumnFilter: false,
        minSize: 65,
      },
      {
        accessorKey: "color_code",
        header: "Color Code",
        enableColumnFilter: false,
        minSize: 53,
        size: 72,
      },
      {
        accessorKey: "file_name",
        header: "File Name",
        enableColumnFilter: false,
        minSize: 48,
        size: 309,
        Cell: ({ renderedCellValue, row }) => (
          <div
            className="remark_lengthy"
            onClick={() => {
              setLengthyRemarks(renderedCellValue);
              setShowRemarksModal(true);
            }}
          >
            {renderedCellValue}
          </div>
        ),
      },
      {
        accessorKey: "file_no",
        header: "File No",
        enableColumnFilter: false,
        minSize: 34,
        size: 105,
      },
      {
        accessorKey: "pysical_file_no",
        header: "Physical File No",
        enableColumnFilter: false,
        minSize: 77,
        size: 108,
      },
      {
        accessorKey: " ",
        header: "Action",
        enableColumnFilter: false,
        minSize: 31,
        size: 78,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <FaPenToSquare
              onClick={() => {
                setview(false);
                handleEdit({
                  id: row.original.file_id,
                  type: false,
                  label: "Edit File Master",
                });
              }}
              style={{ cursor: "pointer", color: "blue" }}
            />
            <FaEye
              onClick={() => {
                setview(true);
                handleEdit({
                  id: row.original.file_id,
                  type: true,
                  label: "Preview File Master",
                });
              }}
              style={{
                cursor: "pointer",
                margin: "0px 5px",
                color: "green",
              }}
            />{" "}
            <FaTrash
              onClick={() => handleDeleteRow(row.original.file_id)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleChangetoggle = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const handleDeleteRow = (id) => {
    const valid = window.confirm("Are you sure you want to Delete?? ");
    if (valid) {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/delete_file",
        {
          file_id: id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          toast.success("Succesfully Deleted !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };
  const CompanyList = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "auth/list_company_user",
        "",
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            setcompanyList(result.data);
            setFormData({ ...formData, company_id: result.data });
          }
        })
        .catch((err) => {
          // showErrorToast(err);
        });
    } catch (err) {
      // showErrorToast(err);
    }
  };
  const handleEdit = (data) => {
    console.log(data, "sameeeer");
    handleShow();
    const val = { ...data };
    val.valid = true;
    seteditData(val);

    try {
      CommonController.commonApiCallFilter(
        "quickAdminDocMangement/preview_file",
        {
          file_id: data.id,
        },
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          let value = data.data;
          let tempData = { ...formData };
          // setvalidSubmit(true);

          for (let key in formData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setFormData(tempData);
        }
      });
    } catch (err) {
      console(err);
      getFileNo();
    }
  };

  const getFileNo = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/generate_file_no",
        {},
        "post",
        "node"
      ).then((data) => {
        if (data.valid) {
          setFormData({ ...formData, file_no: data.data.file_no });
        }
      });
    } catch (err) {
      // showErrorToast(err);
    }
  };

  const submitDoc = async () => {
    try {
      await CommonController.commonApiCallFilter(
        "quickAdminDocMangement/insert_file",
        formData,
        "post",
        "node"
      )
        .then((result) => {
          if (result.valid) {
            // showSuccessToast("Success");
            setview(true);
            setupdateGrid(updateGrid + 1);
            setFormData({
              file_id: 0,
              file_no: "",
              file_name: "",
              file_category_id: "",
              color_code: "",
              pysical_file_no: "",
              company_id: [],
              disable: false,
            });
            handleClose();
            toast.success("New File Added !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error !", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error("Error !", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const handleCountrySelect = (val) => {
    const arr = [...formData.company_id];
    return arr.filter((value) => value.company_id === val.company_id).length > 0
      ? true
      : false;
  };

  const handleBodyFilter = (data) => {
    setFormData(data);
    setfilter(false);
  };
  useEffect(() => {
    CompanyList();
  }, []);

  const [color, setColor] = useColor("#121212");

  const handleColor = (e) => {
    console.log(e.hex);
    setColor(e);
    setFormData({
      ...formData,
      color_code: color.hex,
    });
  };


  return (
    <MainLayout
      pageName="File Master"
      isOpen={() => {
        setShow(true);
        seteditData({ valid: false, label: "Add File Master" });
        getFileNo();
        CompanyList();
      }}
      isAddBtnVisible={true}
    >
      <CommonDataGrid
        url={"quickAdminDocMangement/browse_file"}
        columns={gridColumn}
        body={""}
        // bodyData={() => setvalid(false)}
        jsonUpd={updateGrid}
        bodyData={handleBodyFilter}
        gridHeight={308}
      />

      <ToastContainer />

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        className="add_table_data"
      >
        <Modal.Body>
          <h4>{editData.label}</h4>

          <Form className="mt-3">
            <Row>
              <Col md={6} className="mb-3">
                <TextField
                  // label="File NO*"
                  disabled
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="file_no"
                  value={formData.file_no}
                />
              </Col>
              <Col md={6} className="mb-3">
                <TextField
                  size="small"
                  label="File Name*"
                  variant="outlined"
                  className="w-100"
                  name="file_name"
                  value={formData.file_name}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              <Col md={6} className="mb-3">
                <AutoCompleteDropdown
                  url={"quickAdminDocMangement/list_file_category"}
                  handleDataChange={(val) =>
                    setFormData({
                      ...formData,
                      file_category_id: val.file_category_id,
                      file_category: val.file_category,
                    })
                  }
                  labelName={"File Category"}
                  valueInput={formData.file_category}
                  objLevel={"file_category"}
                  disabled={view}
                  multiple
                />
              </Col>
              <Col md={6} className="mb-3 mb-md-0">
                <TextField
                  size="small"
                  label="Physical File Number*"
                  variant="outlined"
                  className="w-100"
                  name="pysical_file_no"
                  value={formData.pysical_file_no}
                  onChange={handleChange}
                  disabled={view}
                />
              </Col>
              {/* <Col md={3} className="mb-3">
                <ColorPicker onChange={handleChange} />
              </Col> */}
              <Col md={6} className="mb-3">
                <ColorPicker
                  width={400}
                  height={400}
                  color={color}
                  // onChange={setColor}
                  onChange={handleColor}
                  hideHSV
                  dark
                />
                {/* <Saturation height={300} color={color} onChange={setColor} /> */}
                {/* <Hue color={color} onChange={setColor} /> */}
              </Col>
              <Col md={3} className="mb-3">
                <FormControlLabel control={<Switch />} label="Disable" />
              </Col>
              <Col md={12} className=" mb-3">
                <Table bordered size="sm" color="dark">
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyList.length > 0
                      ? companyList.map((val, ind) => {
                          return (
                            <tr key={val.company_id}>
                              <td className="text-left">{val.company_name}</td>
                              <td className="text-center">
                                <FormControlLabel
                                  control={<Checkbox />}
                                  onChange={(e) => {
                                    const valid = e.target.checked;
                                    if (valid) {
                                      const arr = [...formData.company_id];
                                      arr.push(val);
                                      setFormData({
                                        ...formData,
                                        company_id: arr,
                                      });
                                    } else {
                                      const arr = [...formData.company_id];
                                      const index = arr.findIndex(
                                        (value) =>
                                          value.company_id === val.company_id
                                      );
                                      arr.splice(index, 1);
                                      setFormData({
                                        ...formData,
                                        company_id: arr,
                                      });
                                    }

                                    // setformdata({...formdata,company_id})
                                  }}
                                  checked={handleCountrySelect(val)}
                                  name={val.company_id}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </Table>
              </Col>
              <Col md={12}>
                <div className="w-100 mt-1 text-right">
                  {!editData.type ? (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={submitDoc}
                      className="me-3"
                    >
                      {editData.valid ? "Update" : "Save"}
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button variant="danger" size="sm" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
}
